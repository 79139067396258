
import {
    UPDATE_WORKSPACE_DETAILS,
    SET_ACTIVE_FLOW_DETAILS,
    UPDATE_WORKSPACELIST_DETAILS,
    UPDATE_FLOWLIST,
    SET_WORKSPACE_LOADER,
    SET_WORKFLOW_PUBLISHING
} from "../types";

// import workspaceList from "../../mockData/workspaces.json";


const initialState = {
    workspace: null,
    workspaceList: [],
    flowList: [],
    flow: null,
    loading: false,
    publishing: false
}


export default (state = initialState, { type, data }) => {
    switch (type) {    
        case UPDATE_WORKSPACE_DETAILS:
            return { ...state, workspace: data }  
        case UPDATE_WORKSPACELIST_DETAILS:
            return { ...state, workspaceList: data }     
        case UPDATE_FLOWLIST:
            return { ...state, flowList: data }      
        case SET_ACTIVE_FLOW_DETAILS:
            return { ...state, flow: data } 
        case SET_WORKSPACE_LOADER:
            return { ...state, loading: data } 
        case SET_WORKFLOW_PUBLISHING:
            return { ...state, publishing: data } 
        default:
            return state
    }
}