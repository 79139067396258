

import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import arrow_left from "../../../utils/img/arrow-left.png";
import flow_light from "../../../utils/img/flow-light.png";
import help_res from "../../../utils/img/help_res.png";
import single_line from "../../../utils/img/1_line.png";
import two_line from "../../../utils/img/2_line.png";
import ques_icon from "../../../utils/img/ques_icon.png";
import flogo from "../../../utils/img/flow-logo.png";
import yes_icon from "../../../utils/img/yes_icon.png";
import no_icon from "../../../utils/img/no_icon.png";
import Bluejeansicon from "../../../utils/img/Bluejeansicon.png";
import three_line from "../../../utils/img/3_line.png";
import send_sms from "../../../utils/img/send_sms.png";
import email from "../../../utils/img/email.png";


import HelpResponse from './HelpResponse';
import Questions from './Questions';
import NoAnswer from './NoAnswer';
import OnHold from './OnHold';
import SendSms from './SendSms';
import SendEmail from './SendEmail';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Flow(props) {

    const [tile, setTile] = useState(null);
    const [notify, setNotify] = useState({
        show: false,
        message: '',
        type: ''
    });


    const [helpRes, setHelpRes] = useState(props.activeFlow &&
        props.activeFlow.help_response ? props.activeFlow.help_response : '');
    const [noAnsRes, setNoAnsRes] = useState(props.activeFlow &&
        props.activeFlow.no_answer_response ? props.activeFlow.no_answer_response : '');
    const [onHoldRes, setOnHoldRes] = useState(props.activeFlow &&
        props.activeFlow.on_hold_response ? props.activeFlow.on_hold_response : '');
    const [qaOption, setQaOption] = useState(props.activeFlow &&
        props.activeFlow.fk_qa ? props.activeFlow.fk_qa : null);
    const [smsMessage, setSmsMessage] = useState(props.activeFlow &&
        props.activeFlow.sms_body ? props.activeFlow.sms_body : '');
    const [emailMessage, setEmailMessage] = useState(props.activeFlow &&
        props.activeFlow.email_body ? props.activeFlow.email_body : '');
    const [senderEmail, setSenderEmail] = useState(props.activeFlow &&
        props.activeFlow.sender_email ? props.activeFlow.sender_email : '');
    const [receiverPhones, setReceiverPhones] = useState(props.activeFlow &&
        props.activeFlow.receiver_phones ? props.activeFlow.receiver_phones : '');
    const [receiverEmails, setReceiverEmails] = useState(props.activeFlow &&
        props.activeFlow.receiver_emails ? props.activeFlow.receiver_emails : '');

    // console.log(props)

    let routeHistory = useHistory();

    useEffect(() => {
        if (props && !props.activeFlow) {
            routeHistory.push("/dashboard/workspace");
        }
    }, [])


    const changetile = (key) => {
        if (key === tile) {
            setTile(null);
        } else {
            setTile(key);
        }
    }

    const publish = () => {

        if (qaOption) {
            let phones = receiverPhones;
            let emails = receiverEmails;
            if(receiverPhones.slice(-1) === ",") {
                phones = receiverPhones.slice(0, -1);
            }
            if(receiverEmails.slice(-1) === ",") {
                emails = receiverEmails.slice(0, -1);
            }
            if(receiverPhones.startsWith(",")) {
                phones = receiverPhones.slice(1);
            }
            if(receiverEmails.startsWith(",")) {
                emails = receiverEmails.slice(1);
            }
            let data = {
                id: props.activeFlow.id,
                data: {
                    help_response: helpRes,
                    no_answer_response: noAnsRes,
                    on_hold_response: onHoldRes,
                    email_body: emailMessage,
                    sms_body: smsMessage,
                    // sender_email: senderEmail,
                    receiver_emails: emails,
                    // sender_phone: null,
                    receiver_phones: phones,
                    fk_qa: qaOption
                }
            }
            // console.log("-============ ,", data);
            props.updateFlowAction(data, callbackAction);
        } else {
            if (props.qaList && props.qaList.length > 0) {
                setNotify({
                    show: true,
                    message: 'Select Q&A list to update',
                    type: 'error'
                });
                removeSnackBar();
            } else {
                setNotify({
                    show: true,
                    message: 'Add new Q&A list to publish',
                    type: 'error'
                });
                removeSnackBar();
            }
        }

    }

    const callbackAction = (data) => {
        if (data.success) {
            setNotify({
                show: true,
                type: 'success',
                message: data.message
            });
            removeSnackBar();
            props.getWorkflowsListAction(props.activeWorkspace, () => { });
        } else {
            setNotify({
                show: true,
                type: 'error',
                message: data.message
            });
            removeSnackBar();
        }
    }

    const removeSnackBar = () => {
        setTimeout(() => {
            setNotify({
                show: false,
                message: '',
                type: ''
            });
        }, 3000);
    }

    const getSideTile = () => {
        switch (tile) {
            case "HELP":
                return <HelpResponse {...props} helpRes={helpRes} setHelpRes={setHelpRes} />

            case "QUESTION":
                return <Questions {...props} qaOption={qaOption} setQaOption={setQaOption} />

            case "NOANS":
                return <NoAnswer {...props} noAnsRes={noAnsRes} setNoAnsRes={setNoAnsRes} />

            case "ONHOLD":
                return <OnHold {...props} onHoldRes={onHoldRes} setOnHoldRes={setOnHoldRes} />

            case "SMS":
                return <SendSms {...props} smsMessage={smsMessage} setSmsMessage={setSmsMessage}
                    receiverPhones={receiverPhones} setReceiverPhones={setReceiverPhones} />

            case "EMAIL":
                return <SendEmail {...props} emailMessage={emailMessage} setEmailMessage={setEmailMessage}
                    senderEmail={senderEmail} setSenderEmail={setSenderEmail} receiverEmails={receiverEmails}
                    setReceiverEmails={setReceiverEmails} />

            default:
                return null
        }
    }


    return (
        <div className='flex flex-row justify-between'>
            <div className='sm:hidden md:flex w-24 h-screen bg-white border border-r-2 fixed flex flex-col justify-between'>
                <div className='mt-6 md:ml-4'>
                    <NavLink to={"/dashboard/workspace"} >
                        <img src={arrow_left} alt="Home" className='cursor-pointer w-14 h-14 p-4 rounded-tl-lg
                 rounded-br-lg hover:bg-gray-100 md:mb-6 mb-3' />
                    </NavLink>
                </div>
                <img src={flogo} alt="Home" className='w-14 h-14 p-2 ml-2 mb-4' />
            </div>

            {tile && <div className=' w-2/5 ml-24 h-auto bg-white' >
                {getSideTile()}
            </div>}

            <div className='flex flex-col h-auto w-full bg-blue-50'>
                <div className='w-full h-24 mt-5'>
                    <div className='flex flex-row justify-between p-1'>
                        <span></span>
                        <div className='flex flex-row align-middle mt-5 ml-10'>
                            {props.workspaceLoader ? <span className='w-10 h-10 mt-2 animate-spin bg-none border-b-2 border-gray-400 rounded-full'></span>
                                : <img src={flow_light} alt="Flow" className='w-10 h-10 p-1' />}
                            <p className='font-bold text-lg p-2 ml-3' >{props.activeFlow && props.activeFlow.name}</p>
                        </div>
                        {props.workflowPublishing ? <button className='p-3 animate-pulse bg-gray-500 hover:bg-gray-600 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md mr-5 mt-5 h-12 w-auto text-gray-400 '>
                            &nbsp;&nbsp; Publishing... &nbsp;&nbsp;</button> :
                            <button onClick={publish} className='p-3 bg-blue-600 hover:bg-blue-700 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md mr-5 mt-5 h-12 w-auto text-white transition duration-300 
                        ease-in-out transform hover:-translate-y-1'>Save & Publish</button>}
                    </div>
                </div>

                <div className='grid grid-cols-9 gap-4 p-5'>

                    <div className='col-start-1 col-span-4' ></div>
                    <div className='col-start-5 h-40 rounded-lg flex flex-col justify-around align-middle' >
                        <img onClick={() => changetile("HELP")} src={help_res} className='w-16 h-16 p-3 bg-blue-400 border-4 border-white rounded-tr-sm 
                    rounded-bl-sm rounded-tl-2xl rounded-br-2xl shadow-lg ml-4 transition duration-300 
                    ease-in-out transform hover:-translate-y-1 hover:scale-110 cursor-pointer' />
                        {!tile ? <p className='font-sm text-gray-900 mt-2 mb-2'>Help response</p>
                            : <p className='text-gray-900 text-sm mt-2 mb-2'>Help response</p>}
                        <img src={single_line} className='w-3 h-12 ml-10' />
                    </div>
                    <div className='col-start-6 col-span-3' ></div>


                    <div className='col-start-1 col-span-4' ></div>
                    <div className='col-start-5 w-auto h-40 rounded-lg flex flex-col justify-around align-middle' >
                        <img onClick={() => changetile("QUESTION")} src={ques_icon} className='w-16 h-16 p-3 bg-purple-500 border-4 border-white rounded-tr-sm 
                    rounded-bl-sm rounded-tl-2xl rounded-br-2xl shadow-lg ml-4 transition duration-300 
                    ease-in-out transform hover:-translate-y-1 hover:scale-110 cursor-pointer' />
                        {!tile ? <p className='font-sm w-34 text-gray-900 mt-2 mb-2'>Listen for question</p>
                            : <p className='text-sm w-32 text-gray-900 mt-2 mb-2'>Listen for question</p>}
                        <img src={single_line} className='w-3 h-12 ml-10' />
                    </div>
                    <div className='col-start-6 col-span-3' ></div>


                    <div className='col-start-1 col-span-4' ></div>
                    <div className='col-start-5 h-32 rounded-lg flex flex-col justify-around align-middle' >
                        <img onClick={() => changetile("NOANS")} src={help_res} className='w-16 h-16 p-3 bg-blue-400 border-4 border-white rounded-tr-sm 
                    rounded-bl-sm rounded-tl-2xl rounded-br-2xl shadow-lg ml-4 transition duration-300 
                    ease-in-out transform hover:-translate-y-1 hover:scale-110 cursor-pointer' />
                        {!tile ? <p className='font-sm text-gray-900'>No answer response</p>
                            : <p className='text-sm w-32 text-gray-900'>No answer response</p>}
                    </div>
                    <div className='col-start-6 col-span-3' ></div>


                    <div className='col-start-1 col-span-3' ></div>
                    <div className={`col-start-4 w-80 col-span-3 ${!tile && 'ml-14'} -mt-10 h-40 rounded-lg flex flex-col justify-around`} >
                        <img src={two_line} className='w-80 h-20' />
                    </div>
                    <div className='col-start-7 col-span-2' ></div>


                    <div className='col-start-1 col-span-3' ></div>
                    <div className={`col-start-4 h-40 rounded-lg ${!tile ? `ml-8` : `-ml-5`} -mt-10 flex flex-col justify-around align-middle`} >
                        <img src={yes_icon} className='w-16 h-16 p-3 bg-green-500 border-4 border-white rounded-tr-sm 
                    rounded-bl-sm rounded-tl-2xl rounded-br-2xl shadow-lg ml-4 transition duration-300 
                    ease-in-out transform hover:-translate-y-1 hover:scale-110 cursor-pointer' />
                        <p className='font-sm text-gray-900 cursor-pointer ml-8'>Yes</p>
                        <img src={single_line} className='w-3 h-12 ml-10 mt-3' />
                    </div>
                    <div className={`col-start-6 h-40 rounded-lg ${!tile ? `-ml-8` : `ml-6`} -mt-10 flex flex-col justify-around align-middle`} >
                        <img src={no_icon} className='w-16 h-16 p-3 bg-red-500 border-4 border-white rounded-tr-sm 
                    rounded-bl-sm rounded-tl-2xl rounded-br-2xl shadow-lg ml-4 transition duration-300 
                    ease-in-out transform hover:-translate-y-1 hover:scale-110 cursor-pointer' />
                        <p className='font-sm ml-10 text-gray-900 cursor-pointer'>No</p>
                        <img src={single_line} className='w-3 h-12 ml-10 mt-3' />
                    </div>
                    <div className='col-start-6 col-span-3' ></div>


                    <div className='col-start-1 col-span-3' ></div>
                    <div className={`col-start-4 h-32 rounded-lg ${!tile ? `ml-8` : `-ml-5`} -mt-5 flex flex-col justify-around align-middle`} >
                        <img src={Bluejeansicon} className='w-16 h-16 p-3 bg-blue-600 border-4 border-white rounded-tr-sm 
                    rounded-bl-sm rounded-tl-2xl rounded-br-2xl shadow-lg ml-4 transition duration-300 
                    ease-in-out transform hover:-translate-y-1 hover:scale-110 cursor-pointer' />
                        {!tile ? <p className='w-32 text-gray-900 cursor-pointer'>Start meeting</p>
                            : <p className='text-sm text-gray-900 cursor-pointer'>Start meeting</p>}
                    </div>
                    <div className={`col-start-6 h-32 rounded-lg ${!tile ? `-ml-8` : `ml-6`} -mt-5 flex flex-col justify-around align-middle`} >
                        <img src={ques_icon} className='w-16 h-16 p-3 bg-purple-500 border-4 border-white rounded-tr-sm 
                    rounded-bl-sm rounded-tl-2xl rounded-br-2xl shadow-lg ml-4 transition duration-300 
                    ease-in-out transform hover:-translate-y-1 hover:scale-110 cursor-pointer' />
                        {!tile ? <p className='font-sm text-gray-900 cursor-pointer'>Listen for question</p>
                            : <p className='text-sm w-32 text-gray-900 cursor-pointer'>Listen for question</p>}
                    </div>
                    <div className='col-start-6 col-span-3' ></div>


                    <div className='col-start-1 col-span-2' ></div>
                    <div className={`col-start-3 w-80 col-span-3 ${!tile ? 'ml-20' : `-ml-5`} -mt-14 h-40 rounded-lg flex flex-col justify-around`} >
                        <img src={three_line} className='w-80 h-20' />
                    </div>
                    <div className='col-start-7 col-span-2' ></div>


                    <div className='col-start-1 col-span-2' ></div>
                    <div className={`col-start-3 h-32 rounded-lg ${!tile ? `ml-14` : `-ml-12`} -mt-10 flex flex-col justify-around align-middle`} >
                        <img onClick={() => changetile("ONHOLD")} src={help_res} className='w-16 h-16 p-3 bg-blue-400 border-4 border-white rounded-tr-sm 
                    rounded-bl-sm rounded-tl-2xl rounded-br-2xl shadow-lg ml-4 transition duration-300 
                    ease-in-out transform hover:-translate-y-1 hover:scale-110 cursor-pointer' />
                        {!tile ? <p className='w-32 text-gray-900 cursor-pointer'>On hold response</p>
                            : <p className='font-sm text-gray-900 cursor-pointer'>On hold response</p>}
                    </div>
                    <div className={`col-start-4 h-32 rounded-lg ${!tile ? `ml-6` : `-ml-4`} -mt-10 flex flex-col justify-around align-middle`} >
                        <img onClick={() => changetile("SMS")} src={send_sms} className='w-16 h-16 p-3 bg-yellow-400 border-4 border-white rounded-tr-sm 
                    rounded-bl-sm rounded-tl-2xl rounded-br-2xl shadow-lg ml-4 transition duration-300 
                    ease-in-out transform hover:-translate-y-1 hover:scale-110 cursor-pointer' />
                        <p className='font-sm text-gray-900 cursor-pointer ml-5'>Send sms</p>
                    </div>
                    <div className={`col-start-5 h-32 rounded-lg ${!tile ? `ml-0` : `ml-1`} -mt-10 flex flex-col justify-around align-middle`} >
                        <img onClick={() => changetile("EMAIL")} src={email} className='w-16 h-16 p-3 bg-yellow-400 border-4 border-white rounded-tr-sm 
                    rounded-bl-sm rounded-tl-2xl rounded-br-2xl shadow-lg ml-4 transition duration-300 
                    ease-in-out transform hover:-translate-y-1 hover:scale-110 cursor-pointer' />
                        {!tile ? <p className='font-sm ml-5 text-gray-900 cursor-pointer'>Send Email</p>
                            : <p className='font-sm w-32 ml-5 text-gray-900 cursor-pointer'>Send Email</p>}
                    </div>
                    <div className='col-start-6 col-span-3' ></div>

                </div>
            </div>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={notify.show} autoHideDuration={3000}>
                <Alert severity={notify.type} sx={{ width: '100%' }}>
                    {notify.message}
                </Alert>
            </Snackbar>
        </div>
    )
}
