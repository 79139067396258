import React from 'react';
import flowLG from "../../../utils/img/FlowLG.png";


export default function Loader() {

    return (
        <div >
            <div class="flex flex-row">
                <div class="h-screen lg:w-7/12 lg:p-10 lg:pl-14 lg:pt-14">
                    <img src={flowLG} alt="Flow" className='w-28 p-1 lg:-mt-10 lg:-ml-10' />
                    <div className="rounded-md flex flex-col justify-between p-52 ml-96" >
                        <p></p>
                        <p className='w-28 h-28 animate-spin bg-none border-b-2 border-blue-500 rounded-full shadow-md'></p>
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
