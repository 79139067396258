
import React, { useState, useEffect } from 'react';
import flowLG from "../../../utils/img/FlowLG.png";
import user_icon from "../../../utils/img/user.png";
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';

import moment from "moment";

export default function Header() {

    const [showLogout, setShowLogout] = useState(false);
    const [showModal, setShowModal] = useState(false);

    // useEffect(() => {
    //     let expiry = localStorage.getItem("expiry");
    //     if(!expiry || (moment() > moment(expiry))) {
    //         logout()
    //     }
    // }, []);

    const logout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("expiry");
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("token");
        window.location.href = "/";
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }

    return (
        <div className='border border-b-2'>
            <div className='flex flex-row justify-between p-1'>
                <img src={flowLG} alt="Flow" className='w-22 h-16 p-2 mt-3'/>
                <div className='flex flex-col relative'>
                <Tooltip title="logout" placement="bottom" onClick={() => setShowModal(true)} >
                 <img onMouseOut={() => setShowLogout(false)} onMouseEnter={() => setShowLogout(true)} 
                        onMouseLeave={() => setShowLogout(false)} src={user_icon} alt="user" className='h-10 p-2 mt-5 mr-5 cursor-pointer' />
                </Tooltip></div>
            </div>
            <Modal
                open={showModal}
                className='p-28 w-full'
                onClose={() => setShowModal(false)}
            >
                <div className='bg-white border-none h-2/5 w-1/3 ml-96 p-10 mt-10 rounded-lg shadow-2xl'>
                    <h3 className='font-bold text-xl p-1' >Are you sure, you want to logout?</h3>
                    <div className='pt-10 flex flex-row-reverse'>
                        <button onClick={() => setShowModal(false)} className='p-3 bg-blue-400 hover:bg-blue-600 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md ml-5 text-white'>&nbsp;&nbsp;&nbsp;&nbsp;No&nbsp;&nbsp;&nbsp;&nbsp;</button>
                        <button onClick={logout} className='p-3 bg-red-300 hover:bg-red-400 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md ml-5 text-white'>&nbsp;&nbsp;&nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;&nbsp;</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
