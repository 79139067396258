

export const UPDATE_WORKSPACE_DETAILS = "UPDATE_WORKSPACE_DETAILS";
export const UPDATE_WORKSPACE_LIST = "UPDATE_WORKSPACE_LIST";
export const UPDATE_WORKSPACELIST_DETAILS = "UPDATE_WORKSPACELIST_DETAILS";
export const SET_ACTIVE_FLOW_DETAILS = "SET_ACTIVE_FLOW_DETAILS";
export const UPDATE_QA_LIST = "UPDATE_QA_LIST";
export const SET_ACTIVE_QA_LIST = "SET_ACTIVE_QA_LIST";
export const UPDATE_FLOWLIST = "UPDATE_FLOWLIST";
export const ADD_NEW_QA_LIST = "ADD_NEW_QA_LIST";
export const QA_LIST = "QA_LIST";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const SET_LOGIN_LOADER = "SET_LOGIN_LOADER";
export const SET_WORKSPACE_LOADER = "SET_WORKSPACE_LOADER";
export const QA_LIST_LOADER = "QA_LIST_LOADER";
export const SET_WORKFLOW_PUBLISHING = "SET_WORKFLOW_PUBLISHING";
export const QA_LIST_UPDATE = "QA_LIST_UPDATE";
export const QA_LIST_DELETE = "QA_LIST_DELETE";
export const SET_FORGOT_LOADER = "SET_FORGOT_LOADER";
