
import * as actionTypes from "../types";
import * as constants from "../../utils/constants";
import axios from 'axios';
import moment from "moment";


export const loginAction = (data, cb) => async dispatch => {
    dispatch(loaderAction(true));
    try {
        let response = await axios.post(constants.urls.login, {
            username: data.username,
            password: data.password
        }, {
            headers: {
                token: sessionStorage.getItem("token")
            }
        });
        if (response && response.data) {
            if (response.data.success) {
                // console.log("data ---------- ", response.data, response.data.user);
                if (data.remember) {
                    localStorage.setItem("user", JSON.stringify(response.data.user));
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem("expiry", moment().add(7, 'd'));
                }
                sessionStorage.setItem("user", JSON.stringify(response.data.user));
                sessionStorage.setItem("token", response.data.token);
                dispatch(loaderAction(false));
                cb({
                    success: true,
                    message: "login success"
                });
            } else {
                dispatch(loaderAction(false));
                cb({
                    success: false,
                    message: response.data.message
                });
            }
        } else {
            dispatch(loaderAction(false));
            cb({
                success: false,
                message: "something went wrong"
            });
        }
    } catch (e) {
        dispatch(loaderAction(false));
        cb({
            success: false,
            message: "something went wrong"
        });
    }
}


const loaderAction = (loader) => async dispatch => {
    dispatch({
        type: actionTypes.SET_LOGIN_LOADER,
        data: loader
    })
}

export const forgotPasswordAction = (email, cb) => async dispatch => {
    dispatch(forgotLoaderAction(true));
    try {
        let response = await axios.post(constants.urls.forgot, {
            email: email
        });
        if (response && response.data) {
            if (response.data.success) {
                dispatch(forgotLoaderAction(false));
                cb({
                    success: true,
                    message: "Reset link sent to mail, check your inbox"
                });
            } else {
                dispatch(forgotLoaderAction(false));
                cb({
                    success: false,
                    message: response.data.message
                });
            }
        } else {
            dispatch(forgotLoaderAction(false));
            cb({
                success: false,
                message: "something went wrong"
            });
        }
    } catch (e) {
        dispatch(forgotLoaderAction(false));
        cb({
            success: false,
            message: "something went wrong"
        });
    }
}

export const verifyTokenAction = (token, cb) => async dispatch => {
    try {
        let response = await axios.get(`${constants.urls.verifyToken}?token=${token}`);
        if (response && response.data) {
            if (response.data.success) {
                cb({
                    success: true,
                    message: "Valid token"
                });
            } else {
                cb({
                    success: false,
                    message: response.data.message
                });
            }
        } else {
            cb({
                success: false,
                message: "something went wrong"
            });
        }
    } catch (e) {
        cb({
            success: false,
            message: "something went wrong"
        });
    }
}

export const resetPasswordAction = (data, cb) => async dispatch => {
    dispatch(forgotLoaderAction(true));
    try {
        let response = await axios.post(constants.urls.reset, {
            token: data.token,
            password: data.password
        });
        if (response && response.data) {
            if (response.data.success) {
                dispatch(forgotLoaderAction(false));
                cb({
                    success: true,
                    message: "Password updated successfully!"
                });
            } else {
                dispatch(forgotLoaderAction(false));
                cb({
                    success: false,
                    message: response.data.message
                });
            }
        } else {
            dispatch(forgotLoaderAction(false));
            cb({
                success: false,
                message: "something went wrong"
            });
        }
    } catch (e) {
        dispatch(forgotLoaderAction(false));
        cb({
            success: false,
            message: "something went wrong"
        });
    }
}


const forgotLoaderAction = (loader) => async dispatch => {
    dispatch({
        type: actionTypes.SET_FORGOT_LOADER,
        data: loader
    })
}