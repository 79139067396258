
import React from 'react';
import MainComponent from './components';


const App = () =>{
  return <MainComponent />
}


export default App;
