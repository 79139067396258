const base_url = "https://vr5uik45j5.execute-api.us-east-1.amazonaws.com/labs";
// const base_url = "http://localhost:5000/labs";

export const urls = {
    login: base_url + "/user/login",
    listWorkspaces: base_url + "/user/workspaces",
    listWorkflows: base_url + "/user/workflows",
    listqa: base_url + "/user/qalist",
    addQa: base_url + "/user/qa",
    getQa: base_url + "/user/qadetails",
    editQa: base_url + "/user/editqa",
    deleteQa: base_url + "/user/deleteqa",
    editWorkflow: base_url + "/user/editworkflow",
    forgot: base_url + "/user/forgot",
    verifyToken: base_url + "/user/verify_token",
    reset: base_url + "/user/reset",
    getSignedUrl: base_url + "/workspace/logo/geturl",
    uploadLogoUrl: base_url + "/workspace/logo/update"
}