

import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import folder_icon from "../../../utils/img/folder_icon.png";
import ihub_logo from "../../../utils/img/ihub-icon.png";

import moment from "moment";

export default function HomePage(props) {

    const [modalOpen, setModalOpen] = useState(false);
    const [newWorkspaceTitle, setNewWorkspaceTitle] = useState('');

    let routeHistory = useHistory();

    // useEffect(() => {
    //     props.getWorkspaceListAction();
    // }, []);

    useEffect(() => {
        let expiry = localStorage.getItem("expiry");
        if(expiry && (moment() > moment(expiry))) {
            alert("user session expired!")
            logout()
        }
    }, []);

    const logout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("expiry");
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("token");
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }


    const selectWorkspace = async (workspace) => {
        await props.updateWorkspaceAction(workspace);
        routeHistory.push("/dashboard/workspace");
    }

    const addWorkspace = () => {
        props.addWorkspaceAction(newWorkspaceTitle, props.workspaceList);
        setModalOpen(false);
        setNewWorkspaceTitle('');
    }

    console.log(props)
    return (
        <div className='bg-gray-50 w-screen md:pl-20 pl-10 md:pt-10 pt-10'>
            <div className='flex flex-row'>
                <img src={folder_icon} alt="workspaces" className='w-5 h-5' />
                <span className='font-semibold ml-2 cursor-pointer' >Workspaces</span>
            </div>
            <div className='pt-10 flex flex-row'>
                <h3 className='font-bold text-3xl p-1' >Workspaces</h3>
                {/* <button onClick={() => setModalOpen(true)} className='p-3 bg-blue-500 hover:bg-blue-700 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md ml-5 text-white transition duration-300 
                        ease-in-out transform hover:-translate-y-1'>+Add Workspace</button> */}
            </div>

            {props.workspaceLoader ?
                    <div className="h-16 w-full rounded-lg flex flex-row justify-center mt-20">
                        <span className='w-16 h-16 animate-spin bg-none border-b-2 border-blue-500 rounded-full'></span>
                        <p className='p-5 text-gray-400' >Getting workspace list ...</p>
                    </div>
             : <div className='mt-10 p-5 grid xl:grid-cols-6 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6'>
                { props.workspaceList && props.workspaceList.map((item, index) => {
                        return (
                            <div key={index} className='p-8 bg-white border border-gray-200 rounde w-52 h-48 transition duration-300
                 ease-in-out transform hover:-translate-y-1 rounded-tr-sm rounded-bl-sm rounded-tl-3xl 
                 rounded-br-3xl hover:scale-110 cursor-pointer'>
                                <div onClick={() => selectWorkspace(item)} className='p-2'>
                                    { item.image ?  <img src={item.image} className='w-24 h-24 p-3' alt="..." />
                                    : <img src={ihub_logo} className='w-24 h-24 p-3' alt="..." /> }
                                    <div className='pt-3 p-2'>
                                        <h5 className='text-sm'>{ item.name.length > 15 ? `${item.name.slice(0, 15)}...` : item.name }</h5>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </div> }

            <Modal
                open={modalOpen}
                className='p-20 w-full'
            >
                <div className='bg-white h-2/3 w-1/2 ml-80 p-10 mt-10 rounded-lg shadow-2xl'>
                    <h3 className='font-bold text-3xl p-1' >Create new workspace</h3>
                    <div className='flex flex-col p-2'>
                        <label htmlFor="workspace" className="block text-sm font-medium text-gray-800 p-1">
                            Title
                        </label>
                        <input
                            type="text"
                            name="workspace"
                            value={newWorkspaceTitle}
                            onChange={(e) => setNewWorkspaceTitle(e.target.value)}
                            id="workspace"
                            placeholder='My new workspace'
                            className="mt-1 p-3 bg-gray-50 block w-full h-12 shadow-sm sm:text-s border border-gray-400 rounded-md"
                        />
                    </div>
                    <div className='flex flex-col p-2 mt-2'>
                        <label htmlFor="workspace" className="block text-sm font-medium text-gray-800 p-1">
                            Logo (optional)
                        </label>
                        <input
                            type="file"
                            name="workspace"
                            id="workspace"
                            placeholder='My new workspace'
                            className="appearance-none mt-1 p-3 bg-gray-50 block w-full h-12 shadow-sm sm:text-s border border-gray-400 rounded-md"
                        />
                    </div>
                    <div className='pt-10 flex flex-row-reverse'>
                        <button onClick={addWorkspace} className='p-3 bg-blue-500 hover:bg-blue-800 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md ml-5 text-white'>Add Workspace</button>
                        <button onClick={() => setModalOpen(false)} className='p-3 bg-gray-400 hover:bg-red-400 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md ml-5 text-white'>&nbsp;&nbsp;&nbsp;Cancel&nbsp;&nbsp;&nbsp;</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
