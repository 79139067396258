import React, { useState } from 'react';
import flowLG from "../../../utils/img/FlowLG.png";
import Login_img from "../../../utils/img/Login-Image-Flow.png";
import { Tooltip } from '@mui/material';
import { NavLink, useHistory } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ForgotPassword(props) {

    const [email, setEmail] = useState('');
    const [notify, setNotify] = useState({
        show: false,
        message: '',
        type: ''
    });

    const sendResetLink = () => {
        props.forgotPasswordAction(email, (data) => {
            if (data.success) {
                setNotify({
                    show: true,
                    message: data.message,
                    type: 'success'
                });
                setEmail("");
                setTimeout(() => {
                    removeSnackBar();
                }, 3000);
            } else {
                setNotify({
                    show: true,
                    message: data.message,
                    type: 'error'
                });
                setTimeout(() => {
                    removeSnackBar();
                }, 3000);
            }
        });
    }

    const removeSnackBar = () => {
        setNotify({
            show: false,
            message: '',
            type: ''
        });
    }


    return (
        <div >
            <div class="flex flex-row">
                <div class="h-screen lg:w-7/12 lg:p-10 lg:pl-14 lg:pt-14">
                    <NavLink to="/" >
                        <img src={flowLG} alt="Flow" className='w-28 cursor-pointer p-1 lg:-mt-10 lg:-ml-10' />
                    </NavLink>
                    <div className='lg:h-3/4 lg:ml-10 md:ml-8 ml-6 lg:mt-10 md:mt-8 mt-6 lg:p-16 md:p-6 p-4'>

                        <form >
                            <h2 className='font-bold text-4xl p-2'>Forgot your password?</h2>
                            <p className='text-md text-gray-500 p-2'>Don't worry! Just fill in the email that is connect to your
                                account and we'll send you a link to reset your password. </p>
                            <div className="mt-5 p-2">
                                <label htmlFor="email-address" className="block text-sm font-medium text-gray-800">
                                    Email
                                </label>
                                <input
                                    type="text"
                                    name="email-address"
                                    id="email-address"
                                    value={email}
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder='mail@website.com'
                                    className="mt-1 p-2 bg-white block w-full h-12 shadow-sm sm:text-s border border-gray-300 rounded-md"
                                />
                            </div>

                            {props.forgotPasswordLoader ? <div className="h-16 mt-5 w-full bg-gray-400 rounded-lg flex flex-row justify-center">
                                <span className='w-8 h-8 mt-5 animate-spin bg-none border-b-2 border-white rounded-full'></span>
                                <p className='p-5 animate-pulse text-white' >sending reset link</p>
                            </div> :
                                <div className='p-3 text-white'>
                                    {email.length > 3 && (email.includes('@') && email.includes('.')) ?
                                        <button type="button" onClick={sendResetLink} className='w-full h-16 bg-blue-600 rounded-lg p-3 text-white font-medium'>Send reset link</button> :
                                        <Tooltip title={'Enter valid email address'} placement="top" >
                                            <button type="button" disabled className='w-full h-16 bg-gray-400 rounded-lg p-3 text-white font-medium'>
                                                Send reset link
                                            </button></Tooltip>}
                                </div>}

                        </form>

                    </div>
                </div>


                <div className='lg:block hidden bg-banner h-screen w-5/12 p-10'>
                    <div class="login-img">
                        <img src={Login_img} alt="Flow - workflows" className='p-2' />
                    </div>
                    <div className='text-center text-white mt-10' >
                        <h2 className='font-bold text-4xl' >Create your own <br />
                            Voice Workflow AI</h2>
                        <p className=' font-medium text-lg p-5' >Flow helps teams to design, prototype and <br />
                            launch voice workflow assistants.</p>
                    </div>
                </div>

            </div>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={notify.show} autoHideDuration={3000}>
                <Alert severity={notify.type} sx={{ width: '100%' }}>
                    {notify.message}
                </Alert>
            </Snackbar>
        </div>
    )
}
