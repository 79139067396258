
import * as actionTypes from "../types";
import * as constants from "../../utils/constants";
import axios from "axios";


export const getQAListAction = (cb) => async dispatch => {
    dispatch(updateQAListLoader(true));
    try {
        let user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : null;
        if (user) {
            let res = await axios.get(`${constants.urls.listqa}?org=${user.fk_org}`, {
                headers: {
                    token: sessionStorage.getItem("token")
                }
            });
            if (res && res.data) {
                if (res.data.list && res.data.list.length > 0) {
                    dispatch(updateQAList(res.data.list));
                } else {
                    dispatch(updateQAList([]));
                }
            } else {
                dispatch(updateQAList([]));
                cb(false);
            }
            dispatch(updateQAListLoader(false));
        } else {
            dispatch(updateQAList([]));
            dispatch(updateQAListLoader(false));
            cb(false);
        }
    } catch (e) {
        dispatch(updateQAList([]));
        dispatch(updateQAListLoader(false));
        cb(false);
    }

}

const updateQAListLoader = (loading) => dispatch => {
    dispatch({
        type: actionTypes.QA_LIST_LOADER,
        data: loading
    });
}

const updateQAList = (list) => dispatch => {
    dispatch({
        type: actionTypes.UPDATE_QA_LIST,
        data: list
    });
}

export const addNewQAListAction = (title, qaList, cb) => async dispatch => {
    dispatch(setQaUpdateAction(true));
    try {
        let user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : null;
        if (user) {
            let data = {
                org: user.fk_org,
                name: title,
                qa: qaList
            }
            let res = await axios.post(`${constants.urls.addQa}`, data, {
                headers: {
                    token: sessionStorage.getItem("token")
                }
            });
            if (res && res.data && res.data.success) {
                dispatch({
                    type: actionTypes.ADD_NEW_QA_LIST,
                    data: data
                });
                dispatch(getQAListAction(() => {}));
                cb({
                    success: true,
                    message: "QA list added"
                });
            } else {
                cb({
                    success: false,
                    message: res.data.message
                });
            }
            dispatch(setQaUpdateAction(false));
        } else {
            cb({
                success: false,
                message: "Something went wrong!"
            });
            dispatch(setQaUpdateAction(false));
        }
    } catch (e) {
        cb({
            success: false,
            message: "Something went wrong!"
        });
        dispatch(setQaUpdateAction(false));
    }
}


export const editQaAction = (qa, cb) => async dispatch => {
    dispatch(setQaUpdateAction(true));
    try {
        let user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : null;
        if (user) {
            let data = {
                org: user.fk_org,
                name: qa.name,
                list_id: qa.list_id,
                qa: qa.list,
                kbid: qa.kbid
            }
            let res = await axios.post(`${constants.urls.editQa}`, data, {
                headers: {
                    token: sessionStorage.getItem("token")
                }
            });
            if (res && res.data && res.data.success) {
                cb({
                    success: true,
                    message: "QA list updated"
                });
            } else {
                cb({
                    success: false,
                    message: res.data.message
                });
            }
            dispatch(setQaUpdateAction(false));
        } else {
            cb({
                success: false,
                message: "Something went wrong!"
            });
            dispatch(setQaUpdateAction(false));
        }
    } catch (e) {
        cb({
            success: false,
            message: "Something went wrong!"
        });
        dispatch(setQaUpdateAction(false));
    }
}

export const deleteQaAction = (qa, cb) => async dispatch => {
    dispatch(setQaDeleteAction(true));
    try {
        let user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : null;
        if (user) {
            let data = {
                org: user.fk_org,
                name: qa.name,
                list_id: qa.list_id,
                kbid: qa.kbid
            }
            let res = await axios.post(`${constants.urls.deleteQa}`, data, {
                headers: {
                    token: sessionStorage.getItem("token")
                }
            });
            if (res && res.data && res.data.success) {
                dispatch(getQAListAction(() => {}));
                cb({
                    success: true,
                    message: "QA list deleted"
                });
            } else {
                cb({
                    success: false,
                    message: res.data.message
                });
            }
            dispatch(setQaDeleteAction(false));
        } else {
            cb({
                success: false,
                message: "Something went wrong!"
            });
            dispatch(setQaDeleteAction(false));
        }
    } catch (e) {
        cb({
            success: false,
            message: "Something went wrong!"
        });
        dispatch(setQaDeleteAction(false));
    }
}


const setQaUpdateAction = (loading) => dispatch => {
    dispatch({
        type: actionTypes.QA_LIST_UPDATE,
        data: loading
    });
}

const setQaDeleteAction = (loading) => dispatch => {
    dispatch({
        type: actionTypes.QA_LIST_DELETE,
        data: loading
    });
}


export const updateQaListAction = (lists) => async dispatch => {
    dispatch({
        type: actionTypes.UPDATE_QA_LIST,
        data: lists
    });
}


export const getQaAction = (id, cb) => async dispatch => {
    dispatch(updateQAListLoader(true));
    try {
        let res = await axios.get(`${constants.urls.getQa}?list_id=${id}`, {
            headers: {
                token: sessionStorage.getItem("token")
            }
        });
        if (res && res.data && res && res.data.success) {
            dispatch({
                type: actionTypes.QA_LIST,
                data: res.data.list
            });
            cb(res.data);
        }
        dispatch(updateQAListLoader(false));
    } catch (e) {
        cb({
            success: false
        });
        dispatch(updateQAListLoader(false));
    }
}



export const setActiveQaListAction = (list) => async dispatch => {
    dispatch({
        type: actionTypes.SET_ACTIVE_QA_LIST,
        data: list
    });
}