

import Auth from "../components/Auth";

import { connect } from 'react-redux';

import { loginAction, forgotPasswordAction, 
        verifyTokenAction, resetPasswordAction } from "../../../redux/actions/userActions";


const mapStateToProps = state => {
    return {
        user: state.userState.user,
        token: state.userState.token,
        loading: state.userState.loading,
        forgotPasswordLoader: state.userState.forgotLoader
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loginAction: (data, cb) => dispatch(loginAction(data, cb)),
        forgotPasswordAction: (data, cb) => dispatch(forgotPasswordAction(data, cb)),
        verifyTokenAction: (data, cb) => dispatch(verifyTokenAction(data, cb)),
        resetPasswordAction: (data, cb) => dispatch(resetPasswordAction(data, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth)