import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import Layout from './Layout';
import { HomePage, Workspace } from '../../workspaces';
import { QaList, EditableQaList } from "../../qa";
import Flow from '../../flow';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function Dashboard(props) {

    const [notify, setNotify] = useState({
        show: false,
        message: ''
    });

    useEffect(() => {
        props.getWorkspaceListAction(callback);
        props.getQAListAction(callback);
    }, []);

    const callback = (success) => {
        if (!success) {
            setNotify({
                show: true,
                message: 'Something went wrong while fetching list!'
            });
            setTimeout(() => {
                removeSnackBar();
            }, 3000);
        }
    }

    const removeSnackBar = () => {
        setNotify({
            show: false,
            message: ''
        });
    }


    return (
        <Router>
            <Switch>
                <Route path="/flow" >
                    <Flow {...props} />
                </Route>
                <Layout>
                    <Route exact path="/">
                        <HomePage {...props} />
                    </Route>
                    <Route exact path="/dashboard">
                        <HomePage {...props} />
                    </Route>
                    <Route path="/dashboard/workspace">
                        <Workspace {...props} />
                    </Route>
                    <Route exact path="/qa">
                        <QaList {...props} />
                    </Route>
                    <Route exact path="/qa/edit">
                        <EditableQaList {...props} />
                    </Route>
                    <Route exact path="/qa/add">
                        <EditableQaList {...props} />
                    </Route>
                </Layout>
                <Route path="*" >
                    <Redirect to="/dashboard" />
                </Route>
            </Switch>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={notify.show} autoHideDuration={3000}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    {notify.message}
                </Alert>
            </Snackbar>
        </Router>
    )
}
