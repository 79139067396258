import React, { useState, useEffect } from 'react';
import { NavLink, Switch, Route, useParams } from 'react-router-dom';
import home_icon from "../../../utils/img/home_icon.png";
import home_icon_active from "../../../utils/img/home_icon_active.png";
import qa_icon from "../../../utils/img/qa_icon.png";
import qa_icon_active from "../../../utils/img/qa_icon_active.png";


export default function Sidebar() {
    return (

        <Switch>
        <Route exact path="/" children={<ChildComponent />} />
        <Route path="/:id" children={<ChildComponent />} />
        <Route exact path="*" children={<ChildComponent />} />
      </Switch>

    )
}


const ChildComponent = () => {
    let { id } = useParams();
    // console.log("id ", id)
    return (
        <div className='w-20 h-screen border border-r-2'>
        <div className='mt-14 md:ml-3'>
            <NavLink to={"/dashboard"} >
                {id === "dashboard" || id === "" || !id ? 
                 <img src={home_icon_active} alt="Home" className='cursor-pointer w-14 h-14 p-4 rounded-tl-lg
                 rounded-br-lg bg-gray-100 md:mb-6 mb-3' />
                 : <img src={home_icon} alt="Home" className='cursor-pointer w-14 h-14 p-4 rounded-tl-lg
                 rounded-br-lg hover:bg-gray-100 md:mb-6 mb-3' />}
            </NavLink>
            <NavLink to={"/qa"} >
            {id === "qa" ? <img src={qa_icon_active} alt="Q/A" className='w-14 h-14 p-4 bg-gray-100 rounded-tl-lg rounded-br-lg 
            md:mb-6 cursor-pointer mb-3' /> :
            <img src={qa_icon} alt="Q/A" className='w-14 h-14 p-4 rounded-tl-lg rounded-br-lg 
            md:mb-6 cursor-pointer mb-3 hover:bg-gray-100' /> }
            </NavLink>
        </div>
    </div>
    )
}