

import React, { useEffect } from 'react';

import ques_icon from "../../../utils/img/ques_icon.png";

export default function Questions(props) {
    // console.log("-----------", props)

    useEffect(() => {
        if(!props.qaOption) {
            let option = props.qaList && props.qaList.length > 0 ? props.qaList[0].id : null;
            props.setQaOption(option);
        }
    }, [])

    return (
        <div className='flex flex-col fixed'>
            <div className='flex flex-row mt-10'>
                <img src={ques_icon} alt="Listen for question" className='w-16 h-16 p-3 bg-purple-500 border-4 border-white rounded-tr-sm 
                    rounded-bl-sm rounded-tl-2xl rounded-br-2xl shadow-lg ml-4 ' />
                <h4 className='font-semibold text-xl p-5'>Listen for question</h4>
            </div>
            <div className='p-5'>
                <p>Please select the question and answer list that</p>
                <p>the AI should use in order to respond.</p>
            </div>
            <div className='flex flex-col p-2 m-2'>
                <label className='p-2'>Q&A list</label>
                <select value={props.qaOption} onChange={(e) => props.setQaOption(e.target.value)} name="aq-list" id="QA-list" className='p-3 bg-gray-100 border-1 border-black rounder-lg' >
                <option disabled >Select Q&A list</option>
                    {props.qaList && props.qaList.map((item, index) => {
                        return (
                            <option key={index} value={item.id} selected={props.qaOption == item.id} >{item.name}</option>
                        )
                    })}
                </select>

                {/* <button className='mt-10 p-3 w-full bg-blue-600 hover:bg-blue-700 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md h-12 text-white transition duration-300 
                        ease-in-out transform hover:-translate-y-1'>Save</button> */}
            </div>
        </div>
    )
}
