

import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import qa_icon from "../../../utils/img/qa_icon.png";
import qa_icon_active from "../../../utils/img/qa_icon_active.png";
import ihub_logo from "../../../utils/img/ihub-icon.png";
import Modal from '@mui/material/Modal';

export default function QaList(props) {

    // console.log(props)

    const [modalOpen, setModalOpen] = useState(false);
    const [newListTitle, setNewListTitle] = useState('');

    let routeHistory = useHistory();

    const selectedqaList = async (flow) => {
        await props.setActiveQaListAction(flow);
        routeHistory.push("/qa/edit");
    }

    const addNewQAList = () => {
        // props.addNewQAList(newListTitle, props.qaList);
        setModalOpen(false);
        setNewListTitle('');
        selectedqaList({
            name: newListTitle
        });
    }

    return (
        <div className='bg-gray-50 w-screen md:pl-20 pl-10 md:pt-10 pt-10'>
            <div className='flex flex-row'>
                <img src={qa_icon_active} alt="Q/A Lists" className='w-5 h-5' />
                <span className='font-semibold ml-2 cursor-pointer' >Q&A Lists</span>
            </div>
            <div className='pt-10 flex flex-row'>
                <h3 className='font-bold text-3xl p-1' >Q&A Lists</h3>
                <button onClick={() => setModalOpen(true)} className='p-3 bg-blue-500 hover:bg-blue-700 hover:shadow-lg hover:border 
                    hover:border-gray-100 rounded-md ml-5 text-white transition duration-300 
                    ease-in-out transform hover:-translate-y-1'>+Add list</button>
            </div>


            {props.qaLoader ?
                <div className="h-16 w-full rounded-lg flex flex-row justify-center mt-20">
                    <span className='w-16 h-16 animate-spin bg-none border-b-2 border-blue-500 rounded-full'></span>
                    <p className='p-5 text-gray-400' >Getting Q&A list ...</p>
                </div> :
                <div className='mt-10 p-5 grid xl:grid-cols-6 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6'>
                    {props.qaList && props.qaList.map((item, index) => {
                        return (
                            <div onClick={() => selectedqaList(item)} key={index} className='p-8 bg-white border border-gray-200 rounde w-52 h-48 transition duration-300
             ease-in-out transform hover:-translate-y-1 rounded-tr-sm rounded-bl-sm rounded-tl-3xl 
             rounded-br-3xl hover:scale-110 cursor-pointer'>
                                <div className='p-2'>
                                    <img src={qa_icon} className='w-28 h-24 p-1' alt="..." />
                                    <div className='pt-3 p-2'>
                                        <h5 className='text-sm'>{ item.name.length > 15 ? `${item.name.slice(0, 15)}...` : item.name }</h5>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>}


            <Modal
                open={modalOpen}
                className='p-28 w-full'
            >
                <div className='bg-white h-3/5 w-1/2 ml-80 p-10 mt-10 rounded-lg shadow-2xl'>
                    <h3 className='font-bold text-3xl p-1' >Create new Q/A List</h3>
                    <div className='flex flex-col p-2'>
                        <label htmlFor="newlist" className="block text-sm font-medium text-gray-800 p-1">
                            Title
                        </label>
                        <input
                            type="text"
                            name="newlist"
                            id="newlist"
                            value={newListTitle}
                            onChange={(e) => setNewListTitle(e.target.value)}
                            placeholder='My new Q/A list'
                            className="mt-1 p-3 bg-gray-50 block w-full h-12 shadow-sm sm:text-s border border-gray-400 rounded-md"
                        />
                    </div>
                    {/* <div className='flex flex-col p-2 mt-2'>
                        <label htmlFor="workspace" className="block text-sm font-medium text-gray-800 p-1">
                            Logo (optional)
                        </label>
                        <input
                            type="file"
                            name="workspace"
                            id="workspace"
                            placeholder='My new workspace'
                            className="appearance-none mt-1 p-3 bg-gray-50 block w-full h-12 shadow-sm sm:text-s border border-gray-400 rounded-md"
                        />
                    </div> */}
                    <div className='pt-10 flex flex-row-reverse'>
                        <button onClick={addNewQAList} className='p-3 bg-blue-500 hover:bg-blue-800 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md ml-5 text-white'>&nbsp;&nbsp;Add List&nbsp;&nbsp;</button>
                        <button onClick={() => setModalOpen(false)} className='p-3 bg-gray-400 hover:bg-red-400 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md ml-5 text-white'>&nbsp;&nbsp;&nbsp;Cancel&nbsp;&nbsp;&nbsp;</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
