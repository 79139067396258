
import * as actionTypes from "../types";
import * as constants from "../../utils/constants";
import axios from "axios";


export const getWorkspaceListAction = (cb) => async dispatch => {
    dispatch(updateLoaderAction(true));
    try {
        let user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : null;
        if (user) {
            let res = await axios.get(`${constants.urls.listWorkspaces}?org=${user.fk_org}`, {
                headers: {
                    token: sessionStorage.getItem("token")
                }
            });
            if (res && res.data) {
                if (res.data.workspaces && res.data.workspaces.length > 0) {
                    dispatch(updateWorkspaceList(res.data.workspaces));
                } else {
                    dispatch(updateWorkspaceList([]));
                }
            } else {
                dispatch(updateWorkspaceList([]));
                cb(false);
            }
            dispatch(updateLoaderAction(false));
        } else {
            dispatch(updateWorkspaceList([]));
            dispatch(updateLoaderAction(false));
            cb(false);
        }
    } catch (e) {
        dispatch(updateWorkspaceList([]));
        dispatch(updateLoaderAction(false));
        cb(false);
    }

}

const updateLoaderAction = (loading) => async dispatch => {
    dispatch({
        type: actionTypes.SET_WORKSPACE_LOADER,
        data: loading
    })
}

const updateWorkspaceList = (list) => dispatch => {
    dispatch({
        type: actionTypes.UPDATE_WORKSPACELIST_DETAILS,
        data: list
    });
}

export const getWorkflowsListAction = (workspace, cb) => async dispatch => {
    dispatch(updateLoaderAction(true));
    try {
        let res = await axios.get(`${constants.urls.listWorkflows}?workspace=${workspace.id}`, {
            headers: {
                token: sessionStorage.getItem("token")
            }
        });
        if (res && res.data) {
            if (res.data.workflows && res.data.workflows.length > 0) {
                dispatch(updateWorkflowsList(res.data.workflows));
            } else {
                dispatch(updateWorkflowsList([]));
            }
        } else {
            cb(false);
        }
        dispatch(updateLoaderAction(false));
    } catch (e) {
        dispatch(updateWorkflowsList([]));
        dispatch(updateLoaderAction(false));
        cb(false);
    }

}

const updateWorkflowsList = (data) => dispatch => {
    dispatch({
        type: actionTypes.UPDATE_FLOWLIST,
        data: data
    });
}


export const addWorkspaceAction = (title, workspaceList) => async dispatch => {
    let newList = {
        id: workspaceList.length + 1,
        name: title,
        logo: "",
        flows: []
    }
    dispatch({
        type: actionTypes.UPDATE_WORKSPACELIST_DETAILS,
        data: [...workspaceList, newList]
    });
}

export const addFlowAction = (title, flowList, activeWorkspace) => async dispatch => {
    let newFlow = {
        id: flowList.length + 1,
        name: title,
        logo: ""
    }
    activeWorkspace.flows = [...activeWorkspace.flows, newFlow];
    dispatch({
        type: actionTypes.SET_ACTIVE_FLOW_DETAILS,
        data: activeWorkspace
    });
}

export const updateFlowAction = (data, cb) => async dispatch => {
    dispatch(updatePublishingLoader(true));
    try {
        let res = await axios.post(`${constants.urls.editWorkflow}`, data, {
            headers: {
                token: sessionStorage.getItem("token")
            }
        });
        if (res && res.data) {
            if (res.data.success) {
                cb({
                    success: true,
                    message: "Workflow updated successfuly!"
                });
            } else {
                cb({
                    success: false,
                    message: res.data.message
                });
            }
        } else {
            cb({
                success: false,
                message: "Something went wrong!"
            });
        }
        dispatch(updatePublishingLoader(false));
    } catch (e) {
        cb({
            success: false,
            message: "Something went wrong!"
        });
        dispatch(updatePublishingLoader(false));
    }
}

const updatePublishingLoader = (loading) => dispatch => {
    dispatch({
        type: actionTypes.SET_WORKFLOW_PUBLISHING,
        data: loading
    })
}



export const updateWorkspaceAction = (workspace) => async dispatch => {
    dispatch({
        type: actionTypes.SET_ACTIVE_FLOW_DETAILS,
        data: null
    });
    dispatch({
        type: actionTypes.UPDATE_WORKSPACE_DETAILS,
        data: workspace
    });
}

export const setActiveFlowAction = (flow) => async dispatch => {
    dispatch({
        type: actionTypes.SET_ACTIVE_FLOW_DETAILS,
        data: flow
    });
}

export const getSignedUrl = (data, cb) => async dispatch => {
    try {
        let res = await axios.get(`${constants.urls.getSignedUrl}?fileName=${data.fileName}&fileType=${data.fileType}`, {
            headers: {
                token: sessionStorage.getItem("token")
            }
        });
        if (res && res.data) {
            if (res.data.success) {
                cb({
                    success: true,
                    data: res.data.location
                });
            } else {
                cb({
                    success: false,
                    message: res.data.message
                });
            }
        } else {
            cb({
                success: false,
                message: "Something went wrong!"
            });
        }
    } catch (e) {
        cb({
            success: false,
            message: "Something went wrong!"
        });
    }
}

export const updateWorkspaceLogo = (data, cb) => async dispatch => {
    try {
        let res = await axios.post(`${constants.urls.uploadLogoUrl}`, data, {
            headers: {
                token: sessionStorage.getItem("token")
            }
        });
        if (res && res.data) {
            if (res.data.success) {
                cb({
                    success: true,
                    data: res.data.location
                });
            } else {
                cb({
                    success: false,
                    message: res.data.message
                });
            }
        } else {
            cb({
                success: false,
                message: "Something went wrong!"
            });
        }
    } catch (e) {
        cb({
            success: false,
            message: "Something went wrong!"
        });
    }
}