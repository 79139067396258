

import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import folder_icon from "../../../utils/img/folder_icon.png";
// import ihub_logo from "../../../utils/img/ihub-icon.png";
import qa_icon_active from "../../../utils/img/qa_icon_active.png";
import flow_light from "../../../utils/img/flow-light.png";
import save from "../../../utils/img/save.png";
import upload from "../../../utils/img/upload.png";
import delete_icon from "../../../utils/img/delete.png";
import remove_icon from "../../../utils/img/remove.png";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import TableRows from './TableRows';



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function EditableQaList(props) {
    // console.log(props)

    const [qaList, setQaList] = useState([]);
    const [newQues, setNewQues] = useState('');
    const [newAns, setNewAns] = useState('');
    const [newAlternativeQuestions, setAlternativeQuestions] = useState("");
    const [err, setErr] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [notify, setNotify] = useState({
        show: false,
        message: '',
        type: ''
    });


    let routeHistory = useHistory();

    useEffect(() => {
        if (props && !props.activeqaList) {
            routeHistory.push("/qa");
        } else if (props.activeqaList.id) {
            props.getQaAction(props.activeqaList.id, (data) => {
                if (data.success) {
                    setQaList(data.list);
                } else {
                    setQaList([]);
                    callbackAction();
                }
            })
        }
    }, []);

    const callbackAction = () => {
        setNotify({
            show: true,
            message: 'Something went wrong while getting Q & A list',
            type: "error"
        });
        removeSnackBar();
    }

    const removeSnackBar = () => {
        setTimeout(() => {
            setNotify({
                show: false,
                message: '',
                type: ''
            });
        }, 3000);
    }


    const addToList = () => {
        if (newQues.length > 0
            && newAns.length > 0) {
            let newList = [...qaList, {
                q: newQues,
                a: newAns,
                aq: newAlternativeQuestions.split("/").filter(i => i.length > 0)
            }];
            setQaList(newList);
            setNewQues('');
            setNewAns('');
            setAlternativeQuestions("");
        } else {
            setErr(true);
            setTimeout(() => {
                setErr(false);
            }, 1500);
        }
    }

    const saveList = () => {
        if (props.activeqaList.id) {
            updateQAList();
        } else {
            addNewQAList();
        }
    }

    const updateQAList = () => {
        if (qaList.length > 0) {
            let data = {
                name: props.activeqaList.name,
                list_id: props.activeqaList.id,
                list: qaList,
                kbid: props.activeqaList.ms_kb_id
            }
            props.editQaAction(data, (res) => {
                if (res.success) {
                    setNotify({
                        show: true,
                        message: res.message,
                        type: 'success'
                    });
                    removeSnackBar();
                } else {
                    setNotify({
                        show: true,
                        message: res.message,
                        type: 'error'
                    });
                    removeSnackBar();
                }
            });
        } else {
            setNotify({
                show: true,
                message: 'Add mininum one Q&A',
                type: 'error'
            });
            removeSnackBar();
        }
    }

    const addNewQAList = () => {
        if (qaList.length > 0) {
            props.addNewQAListAction(props.activeqaList.name, qaList, (res) => {
                if (res.success) {
                    setNotify({
                        show: true,
                        message: res.message,
                        type: 'success'
                    });
                    removeSnackBar();
                    routeHistory.push("/qa");
                } else {
                    setNotify({
                        show: true,
                        message: res.message,
                        type: 'error'
                    });
                    removeSnackBar();
                }
            });
        } else {
            setNotify({
                show: true,
                message: 'Add mininum one Q&A',
                type: 'error'
            });
            removeSnackBar();
        }
    }

    const removeFromList = (id) => {
        let newList = qaList.filter((item, index) => item.id != id);
        setQaList(newList);
    }

    const editQna = (row) => {
        let newList = qaList.filter((item, index) => {
            if (item.id == row.id) {
                item.q = row.q
                item.a = row.a
                item.aq = row.aq
                return item
            } else return item
        });
        setQaList(newList);
    }

    const removeList = () => {
        setQaList([]);
    }

    const parseCsvText = (text) => {
        let firstSplit = text.split("\n");
        let list = qaList;
        for (let i = 0; i < firstSplit.length; i++) {
            let replacedText = firstSplit[i].replace("\r", "");
            let secSplit = replacedText.split(",");
            list = [...list, { id: i + 1, q: secSplit[0], a: secSplit[1], aq: secSplit.slice(2) }]
        }
        return list
    }

    const handleCSVFile = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = async function (res) {
            const text = res.target.result;
            if (text.length > 0) {
                let newList = await parseCsvText(text);
                setQaList(newList);
            }
        }
        reader.readAsText(file);
    }

    const deleteqa = () => {
        setShowModal(false);
        let data = {
            name: props.activeqaList.name,
            list_id: props.activeqaList.id,
            kbid: props.activeqaList.ms_kb_id
        }
        props.deleteQaAction(data, (res) => {
            if (res.success) {
                setNotify({
                    show: true,
                    message: res.message,
                    type: 'success'
                });
                removeSnackBar();
                setTimeout(() => {
                    routeHistory.push("/qa");
                }, 1500);
            } else {
                setNotify({
                    show: true,
                    message: res.message,
                    type: 'error'
                });
                removeSnackBar();
            }
        });
    }


    const openUploader = (e) => {
        let ipel = document.createElement("input");
        ipel.type = "file";
        ipel.accept = ".csv";
        ipel.className = "sr-only";
        ipel.id = "file-upload";
        ipel.name = "file-upload";
        ipel.multiple = false;
        ipel.value = null;
        ipel.addEventListener("change", handleCSVFile);
        ipel.click();
    }

    return (
        <div className='bg-gray-50 h-screen w-screen md:pl-20 pl-10 md:pt-10 pt-10'>
            <div className='flex flex-row justify-between'>
                <div className='flex flex-row' >
                    <img src={qa_icon_active} alt="workspaces" className='w-5 h-5' />
                    <span className='font-sm ml-2 cursor-pointer' >
                        <NavLink to={'/qa'} >
                            Q&A Lists
                        </NavLink>
                    </span>
                    <span className='ml-2' > {`>`} </span>
                    <span className='font-sm ml-2 cursor-pointer' >{props.activeqaList && props.activeqaList.name}</span>

                </div>

                <div className='flex flex-row'>
                    {props.qaUpdateLoader ?
                        <button className='p-3 bg-gray-500 hover:bg-gray-600 hover:shadow-lg hover:border 
                    hover:border-gray-100 rounded-md text-gray-300 flex flex-row'>
                            <span className='w-6 h-6 animate-spin bg-none mr-2  border-l border-r border-white rounded-full'></span>
                            {/* <img src={save} alt="save list" className='w-4 h-4 mt-1 mr-2' /> */}
                            <p className='animate-pulse'>{props.activeqaList.id ? 'Updating list...' : 'Adding list...'}</p></button>
                        : <button onClick={saveList} className='p-3 bg-blue-500 hover:bg-blue-700 hover:shadow-lg hover:border 
                    hover:border-gray-100 rounded-md text-white transition duration-300 
                    ease-in-out transform hover:-translate-y-1 flex flex-row'>
                            <img src={save} alt="save list" className='w-4 h-4 mt-1 mr-2' />Save list</button>}
                    <button onClick={openUploader} className="cursor-pointer p-3 w-36 bg-gray-300 hover:bg-gray-400 hover:shadow-lg hover:border 
                    hover:border-gray-100 rounded-md text-white transition duration-300 
                    ease-in-out transform hover:-translate-y-1 ml-3 flex flex-row">
                        <label
                            htmlFor="file-upload"
                            className="flex flex-row cursor-pointer"
                        >
                            <img src={upload} alt="save list" className='w-5 h-5 mt-1 mr-2' />
                            <span>Upload csv</span>
                            {/* <input id="file-upload" accept='.csv' multiple={false}
                                onChange={handleCSVFile} name="file-upload" type="file" className="sr-only hidden"></input> */}
                        </label>
                    </button>
                    <button onClick={removeList} className='p-3 bg-red-300 hover:bg-red-400 hover:shadow-lg hover:border 
                    hover:border-gray-100 rounded-md text-white transition duration-300 
                    ease-in-out transform hover:-translate-y-1 ml-3 mr-3 flex flex-row'>
                        <img src={remove_icon} alt="save list" className='w-5 h-5 mt-1 mr-2' />Remove all</button>

                    {props.qaDeleteLoader ? <button className='p-3 bg-red-500 ml-1 mr-3 hover:bg-red-600 hover:shadow-lg hover:border 
                    hover:border-gray-100 rounded-md text-gray-300 flex flex-row'>
                        <span className='w-6 h-6 animate-spin bg-none mr-2  border-l border-r border-white rounded-full'></span>
                        <p className='animate-pulse'>Deleting list</p></button>
                        : <button onClick={() => setShowModal(true)} className='p-3 bg-red-400 hover:bg-red-500 hover:shadow-lg hover:border 
                    hover:border-gray-100 rounded-md text-white transition duration-300 
                    ease-in-out transform hover:-translate-y-1 ml-1 mr-3 flex flex-row'>
                            <img src={delete_icon} alt="save list" className='w-4 h-4 mt-1 mr-2' />Delete list</button>}

                </div>

            </div>

            {props.qaLoader ? <div className="h-16 w-full rounded-lg flex flex-row justify-center mt-20">
                <span className='w-10 h-10 animate-spin bg-none border-b-2 border-blue-500 rounded-full'></span>
            </div> :
                qaList && qaList.length > 0 ? <div className="flex flex-col p-5 h-2/3" >
                    <TableContainer component={Paper}>
                        <Table stickyHeader aria-label="collapsible table">
                            <TableHead >
                                <TableRow >
                                    <TableCell />
                                    <TableCell >
                                        <p className='text-gray-700 font-bold italic' > Question</p>
                                    </TableCell>
                                    <TableCell >
                                        <p className='text-gray-700 font-bold italic' >Answer</p>
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {qaList.map((row, index) => {
                                    return (
                                        <TableRows key={row.id} editQna={editQna}
                                            removeFromList={removeFromList} row={row} />)
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div> : <div className='flex flex-row justify-between border-2 border-gray-200 border-t-0 h-20'>
                    <div className='flex flex-row justify-between w-full p-5'>
                        <p className='p-2' ></p>
                        <p className='p-2 overflow-hidden text-gray-400' >No Questions and Answers found</p>
                        <p className='p-2' ></p>
                    </div>
                </div>}

            {/* <div className='flex flex-col p-5 mt-5 '>
                <div className='flex flex-row justify-between bg-gray-200 h-12'>
                    <div className='flex flex-row justify-between bg-gray-300 w-1/2'>
                        <p className='p-2' ></p>
                        <p className='p-3 font-semibold' >Questions</p>
                        <p className='p-2' ></p>
                    </div>
                    <div className='flex flex-row justify-between bg-gray-200 w-1/2'>
                        <p className='p-2' ></p>
                        <p className='p-3 font-semibold' >Answers</p>
                        <p className='p-2' ></p>
                    </div>
                </div>

                {props.qaLoader ? <div className="h-16 w-full rounded-lg flex flex-row justify-center mt-20">
                    <span className='w-10 h-10 animate-spin bg-none border-b-2 border-blue-500 rounded-full'></span>
                </div> :
                    qaList && qaList.length > 0 ?
                        qaList.map((item, index) => {
                            return (
                                <div key={index} className='flex flex-row justify-between border-2 border-gray-200 border-t-0 h-auto'>
                                    <div className='flex flex-row justify-between  w-1/2'>
                                        <p className='p-2 pr-4 ml-2 border-r-2 border-gray-200' >{index + 1}</p>
                                        <p className='p-2 overflow-ellipsis' >{item.q}</p>
                                        <p className='p-2 border-r-2 border-gray-200' ></p>
                                    </div>
                                    <div className='flex flex-row justify-between w-1/2 h-auto'>
                                        <p className='p-2' ></p>
                                        <p className='p-2 overflow-ellipsis w-11/12' >{item.a}</p>
                                        <div onClick={() => removeFromList(index)} className='p-2 flex flex-row w-auto cursor-pointer rounded-lg'>
                                            <img src={delete_icon} alt="save list" className={`w-6 h-6 mt-1 mr-2 `} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        : <div className='flex flex-row justify-between border-2 border-gray-200 border-t-0 h-20'>
                            <div className='flex flex-row justify-between w-full p-5'>
                                <p className='p-2' ></p>
                                <p className='p-2 overflow-hidden text-gray-400' >No Questions and Answers found</p>
                                <p className='p-2' ></p>
                            </div>
                        </div>}

            </div> */}

            { !props.qaLoader && <div className='flex flex-col'>
                <div className='flex flex-row justify-between h-10'>
                    <div className='flex flex-row  w-3/4 border-2 border-gray-200'>
                        {/* <p className='p-2 pl-3 pr-4 border-r-2 bg-white' >
                            {props.activeqaList && qaList &&
                                qaList.length > 0 ? qaList.length + 1 : 1}</p> */}
                        <input onChange={(e) => setNewQues(e.target.value)} type="text" placeholder='Question'
                            className={`p-2 overflow-hidden ${err && !newQues.length > 0 ? 'bg-red-50 border border-red-300' : 'bg-white'} w-full`} value={newQues} ></input>
                        {/* <button className='p-2 border-2 rounded-md border-gray-300 
                         transition duration-300 text-sm font-semibold 
                        ease-in-out transform'>+</button> */}
                    </div>
                </div>
                <div className='flex flex-row justify-between w-full'>
                    <input type="text" placeholder='Answer' onChange={(e) => setNewAns(e.target.value)} value={newAns}
                        className={`p-2 border-2 border-gray-200 h-10 overflow-hidden w-2/3 ${err && !newAns.length > 0 ? 'bg-red-50 border border-red-300' : 'border-t-0 bg-white'}`} ></input>
                    <button onClick={addToList} className='p-2 mr-10 border-2 rounded-md border-blue-300 bg-blue-200
                         transition duration-300 hover:bg-blue-500 text-sm font-semibold hover:border-blue-500
                        ease-in-out transform hover:-translate-y-1 hover:scale-110'>ADD TO LIST</button>
                </div>

                <div className='flex flex-row justify-between'>
                    <div className='flex flex-row  w-3/4 border-2 border-gray-200'>
                        <textarea name="message" id="" rows="3" value={newAlternativeQuestions}
                            onChange={(e) => setAlternativeQuestions(e.target.value)} className='p-2 w-full'
                            placeholder="Add alternative questions separated by /
                            Eg:- This is my first alternative question / This is my second alternative question / ...">
                        </textarea>
                    </div>
                </div>
            </div>}

            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={notify.show} autoHideDuration={3000}>
                <Alert severity={notify.type} sx={{ width: '100%' }}>
                    {notify.message}
                </Alert>
            </Snackbar>
            <Modal
                open={showModal}
                className='p-28 w-full'
                onClose={() => setShowModal(false)}
            >
                <div className='bg-white border-none h-2/5 w-1/3 ml-96 p-10 mt-10 rounded-lg shadow-2xl'>
                    <h3 className='font-bold text-xl p-1' >Are you sure, you want to delete
                        <i> {props.activeqaList && props.activeqaList.name}</i> ?</h3>
                    <div className='pt-10 flex flex-row-reverse'>
                        <button onClick={() => setShowModal(false)} className='p-3 bg-blue-400 hover:bg-blue-600 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md ml-5 text-white'>&nbsp;&nbsp;&nbsp;&nbsp;No&nbsp;&nbsp;&nbsp;&nbsp;</button>
                        <button onClick={deleteqa} className='p-3 bg-red-400 hover:bg-red-500 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md ml-5 text-white'>&nbsp;&nbsp;&nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;&nbsp;</button>
                    </div>
                </div>
            </Modal>

        </div>
    )
}
