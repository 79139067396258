

import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import folder_icon from "../../../utils/img/folder_icon.png";
import upload from "../../../utils/img/upload.png";
import settings_icon from "../../../utils/img/setting.png";
// import ihub_logo from "../../../utils/img/ihub-icon.png";
import flow_light from "../../../utils/img/flow-light.png";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ihub_logo from "../../../utils/img/ihub-icon.png";
import axios from 'axios';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Workspace(props) {
    // console.log(props)

    const [modalOpen, setModalOpen] = useState(false);
    const [newFlowTitle, setNewFlowTitle] = useState('');
    const [notify, setNotify] = useState({
        show: false,
        message: ''
    });

    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [showUploadLoader, setShowUploadLoader] = useState(false);
    const [logo, setLogo] = useState(props.activeWorkspace ? props.activeWorkspace.image : null);

    let routeHistory = useHistory();

    useEffect(() => {
        if (props && !props.activeWorkspace) {
            routeHistory.push("/dashboard");
        } else if (!props.activeFlow) {
            props.getWorkflowsListAction(props.activeWorkspace, callbackAction);
        }
    }, []);


    const callbackAction = (success) => {
        if (!success) {
            setNotify({
                show: true,
                message: 'Something went wrong while getting workflows list'
            });
            setTimeout(() => {
                removeSnackBar();
            }, 3000);
        }
    }

    const removeSnackBar = () => {
        setNotify({
            show: false,
            message: ''
        });
    }


    const selectedWorkflow = async (flow) => {
        await props.setActiveFlowAction(flow);
        routeHistory.push("/flow");
    }


    const addFlow = () => {
        props.addFlowAction(newFlowTitle, props.workspaceList, props.activeWorkspace);
        setModalOpen(false);
        setNewFlowTitle('');
    }


    const updateLogo = () => {
        setShowUploadLoader(true);
        let data = {
            fileName: logo.name,
            fileType: logo.type
        }
        props.getSignedUrl(data, (res) => {
            if (res.success) {
                let config = {
                    method: 'PUT',
                    url: res.data.url,
                    headers: {
                        'Content-Type': logo.type
                    },
                    data: logo
                };
                axios(config)
                    .then(function (response) {
                        let updateData = {
                            workspace: props.activeWorkspace.id,
                            location: res.data.location
                        }
                        props.updateWorkspaceLogo(updateData, (updated) => {
                            if (updated.success) {
                                props.getWorkspaceListAction();
                                setShowUploadLoader(false);
                                setSettingsModalOpen(false);
                            } else {
                                setShowUploadLoader(false);
                                setNotify({
                                    show: true,
                                    message: res.message
                                });
                                setTimeout(() => {
                                    removeSnackBar();
                                }, 2000);
                            }
                        })
                    })
                    .catch(function (error) {
                        setShowUploadLoader(false);
                        setNotify({
                            show: true,
                            message: "something went wrong"
                        });
                        setTimeout(() => {
                            removeSnackBar();
                        }, 2000);
                    });

            } else {
                setShowUploadLoader(false);
                setNotify({
                    show: true,
                    message: res.message
                });
                setTimeout(() => {
                    removeSnackBar();
                }, 2000);
            }
        })
    }


    const setImage = (img) => {
        console.log(img)
        setLogo(img);
        let fr = new FileReader();
        fr.onload = function () {
            document.getElementById("workspace-logo").src = fr.result;
        }
        fr.readAsDataURL(img);
    }

    return (
        <div className='bg-gray-50 w-screen md:pl-20 pl-10 md:pt-10 pt-10'>
            <div className='flex flex-row'>
                <img src={folder_icon} alt="workspaces" className='w-5 h-5' />
                <span className='font-semibold ml-2 cursor-pointer' >
                    <NavLink to={'/dashboard'} >
                        Workspaces
                    </NavLink>
                </span>
                <span className='ml-2' > {`>`} </span>
                <span className='font-semibold ml-2 cursor-pointer' >{props.activeWorkspace && props.activeWorkspace.name}</span>
                <span className='ml-2' > {`>`} </span>
                <span className='font-semibold ml-2 cursor-pointer' >Flows</span>
            </div>
            <div className='pt-10 flex flex-row justify-between'>
                <div className='flex flex-row'>
                    <h3 className='font-bold text-3xl p-1' >Workflows</h3>
                    {/* <button onClick={() => setModalOpen(true)} className='p-3 bg-blue-500 hover:bg-blue-700 hover:shadow-lg hover:border 
                    hover:border-gray-100 rounded-md ml-5 text-white transition duration-300 
                    ease-in-out transform hover:-translate-y-1'>+Add Flow</button> */}
                </div>
                <div className='flex flex-row mr-10'>
                    <img onClick={() => setSettingsModalOpen(true)} src={settings_icon} alt="save list" className='w-10 h-10 mr-5 p-1 rounded-full hover:shadow-lg cursor-pointer' />
                    {/* <button className="cursor-pointer p-3 w-36 bg-blue-400 hover:bg-blue-500 hover:shadow-lg hover:border 
                    hover:border-gray-100 rounded-md text-white transition duration-300 
                    ease-in-out transform hover:-translate-y-1 ml-3 flex flex-row">
                        <label
                            htmlFor="file-upload"
                            className="flex flex-row cursor-pointer"
                        >
                            <img src={upload} alt="save list" className='w-5 h-5 mt-1 mr-2' />
                            <span>Upload logo</span>
                            <input id="file-upload" accept='image' multiple={false}
                             name="file-upload" type="file" className="sr-only" />
                        </label>
                    </button> */}
                </div>
            </div>
            {props.workspaceLoader ?
                <div className="h-16 w-full rounded-lg flex flex-row justify-center mt-20">
                    <span className='w-16 h-16 animate-spin bg-none border-b-2 border-blue-500 rounded-full'></span>
                    <p className='p-5 text-gray-400' >Getting workflows list ...</p>
                </div> :
                <div className='mt-10 p-5 grid xl:grid-cols-6 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6'>
                    {props.activeWorkspace && props.flowList && props.flowList.map((item, index) => {
                        return (
                            <div key={index} className='p-8 bg-white border border-gray-200 rounde w-52 h-48 transition duration-300
                ease-in-out transform hover:-translate-y-1 rounded-tr-sm rounded-bl-sm rounded-tl-3xl 
                rounded-br-3xl hover:scale-110 cursor-pointer'>
                                <div onClick={() => selectedWorkflow(item)} className='p-2'>
                                    <img src={flow_light} className='h-24 p-1 pl-3' alt="..." />
                                    <div className='pt-3 p-2'>
                                        <h5 className='text-sm'>{item.name.length > 15 ? `${item.name.slice(0, 15)}...` : item.name}</h5>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>}
            <Modal
                open={modalOpen}
                className='p-28 w-full'
            >
                <div className='bg-white h-3/5 w-1/2 ml-80 p-10 mt-10 rounded-lg shadow-2xl'>
                    <h3 className='font-bold text-3xl p-1' >Create new flow</h3>
                    <div className='flex flex-col p-2'>
                        <label htmlFor="workspace" className="block text-sm font-medium text-gray-800 p-1">
                            Title
                        </label>
                        <input
                            type="text"
                            name="workspace"
                            value={newFlowTitle}
                            onChange={(e) => setNewFlowTitle(e.target.value)}
                            id="workspace"
                            placeholder='My new flow'
                            className="mt-1 p-3 bg-gray-50 block w-full h-12 shadow-sm sm:text-s border border-gray-400 rounded-md"
                        />
                    </div>
                    {/* <div className='flex flex-col p-2 mt-2'>
                        <label htmlFor="workspace" className="block text-sm font-medium text-gray-800 p-1">
                            Logo (optional)
                        </label>
                        <input
                            type="file"
                            name="workspace"
                            id="workspace"
                            placeholder='My new workspace'
                            className="appearance-none mt-1 p-3 bg-gray-50 block w-full h-12 shadow-sm sm:text-s border border-gray-400 rounded-md"
                        />
                    </div> */}
                    <div className='pt-10 flex flex-row-reverse'>
                        <button onClick={addFlow} className='p-3 bg-blue-500 hover:bg-blue-800 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md ml-5 text-white'>&nbsp;&nbsp;Add Flow&nbsp;&nbsp;</button>
                        <button onClick={() => setModalOpen(false)} className='p-3 bg-gray-400 hover:bg-red-400 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md ml-5 text-white'>&nbsp;&nbsp;&nbsp;Cancel&nbsp;&nbsp;&nbsp;</button>
                    </div>
                </div>
            </Modal>
            <Modal
                open={settingsModalOpen}
                className='p-28 w-full'
            >
                <div className='bg-white h-auto w-1/2 ml-80 p-10 mt-10 rounded-lg shadow-2xl'>
                    <h3 className='font-bold text-3xl p-1' >Update workspace</h3>
                    <div className='flex flex-row justify-between w-full'>
                        <div className='p-3 w-full flex flex-row justify-between' >
                            <div></div>
                            <div className='flex flex-col'>
                                <img id="workspace-logo" src={logo} className='w-28 h-28 p-2' alt="workspace logo" />
                                <div className='flex flex-col'>
                                    <label className='p-2 cursor-pointer w-36 h-10 bg-gray-300 hover:bg-gray-400 hover:shadow-lg hover:border 
                            hover:border-gray-100 rounded-md text-black text-md'>Upload new file
                                        <input className='opacity-0' onChange={(e) => setImage(e.target.files[0])} type="file" /></label>
                                </div>
                            </div>
                            <div></div>
                        </div>


                        {/* <div className='flex flex-col p-2 w-56'>
                            <label htmlFor="workspace" className="block text-sm font-medium text-gray-800 p-1">
                                workspace
                            </label>
                            <input
                                type="text"
                                name="workspace"
                                value={props.activeWorkspace ? props.activeWorkspace.name : ''}
                                id="workspace"
                                disabled
                                className="mt-1 p-3 overflow-ellipsis bg-gray-100 block w-full h-10 shadow-sm sm:text-s border border-gray-400 rounded-md"
                            />
                        </div> */}
                    </div>
                    {/* <div className='flex flex-col p-2 mt-2'>
                        <label htmlFor="workspace" className="block text-sm font-medium text-gray-800 p-1">
                            Logo (optional)
                        </label>
                        <input
                            type="file"
                            name="workspace"
                            id="workspace"
                            placeholder='My new workspace'
                            className="appearance-none mt-1 p-3 bg-gray-50 block w-full h-12 shadow-sm sm:text-s border border-gray-400 rounded-md"
                        />
                    </div> */}
                    <div className='pt-10 flex flex-row-reverse'>

                        {showUploadLoader ?
                            <button className='p-3 cursor-wait bg-gray-500 hover:bg-gray-800 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md ml-5 text-white'>&nbsp;&nbsp;Updating&nbsp;&nbsp;</button> :
                            <button onClick={updateLogo} className='p-3 bg-blue-500 hover:bg-blue-800 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md ml-5 text-white'>&nbsp;&nbsp;Update&nbsp;&nbsp;</button>}

                        <button onClick={() => setSettingsModalOpen(false)} className='p-3 bg-gray-400 hover:bg-red-400 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md ml-5 text-white'>&nbsp;&nbsp;&nbsp;Cancel&nbsp;&nbsp;&nbsp;</button>
                    </div>
                </div>
            </Modal>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={notify.show} autoHideDuration={3000}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    {notify.message}
                </Alert>
            </Snackbar>
        </div>
    )
}
