

import React, { useState, useEffect } from 'react';

import send_email from "../../../utils/img/email.png";

export default function SendEmail(props) {
    const [active, setActive] = useState("MESSAGE");
    const [contactList, setContactList] = useState([]);
    const [name, setname] = useState('');
    const [email, setEmail] = useState('');
    const [err, setErr] = useState(false);

    useEffect(() => {
        parseContacts();
    }, []);

    const parseContacts = () => {
        let list = props.receiverEmails.split(",");
        let newList = [];
        for (let i = 0; i < list.length; i++) {
            if (list[i].length > 0) {
                let tempList = list[i].split(";");
                newList = [...newList, { name: tempList[0], email: tempList[1] }]
            }
        }
        setContactList(newList);
    }

    const addContact = () => {
        if (name.trim().length > 0 && (email.trim().length > 0 && validateEmail(email.trim()))) {
            let newList = [...contactList, { name: name, email: email }];
            let newString = props.receiverEmails + `,${name};${email}`;
            setContactList(newList);
            props.setReceiverEmails(newString);
            setname('');
            setEmail('');
        } else {
            setErr(true);
            setTimeout(() => {
                setErr(false);
            }, 1500);
        }
    }

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    return (
        <div className='flex flex-col fixed'>
            <div className='flex flex-row mt-10'>
                <img src={send_email} alt="Listen for question" className='w-16 h-16 p-3 bg-yellow-400 border-4 border-white rounded-tr-sm 
                    rounded-bl-sm rounded-tl-2xl rounded-br-2xl shadow-lg ml-4 ' />
                <h4 className='font-semibold text-xl p-5'>Send Email</h4>
            </div>

            <div className='p-5 flex flex-row'>
                {active === "MESSAGE" ? <p className='p-5 font-semibold text-medium cursor-pointer text-blue-500 border-b-2 border-blue-500'>Message</p>
                    : <p onClick={() => setActive("MESSAGE")} className='p-5 font-semibold text-medium cursor-pointer'>Message</p>}
                {active === "CONTACTS" ? <p className='p-5 font-semibold text-medium cursor-pointer text-blue-500 border-b-2 border-blue-500'>Contacts</p>
                    : <p onClick={() => setActive("CONTACTS")} className='p-5 font-semibold text-medium cursor-pointer'>Contacts</p>}
            </div>

            {active === "MESSAGE" &&
                <div>
                    <div className='p-5 -mb-5 -mt-3'>
                        <p>Please write out the email message to send</p>
                        <p>out when human assistance is requested by</p>
                        <p>the user.</p>
                    </div>
                    <div className='flex flex-col p-2 m-2 mt-0'>
                        <label className=' p-2'>Message</label>
                        <textarea name="message" id="" defaultValue={props.emailMessage} onChange={(e) => props.setEmailMessage(e.target.value)}
                            rows="4" className='p-3 border-2 border-gray-200 rounded-lg'
                            placeholder="Type in your text message here and use the meeting link button below to add the link to the meeting in your message."></textarea>
                        {/* <label className='p-2 bg-gray-300 rounded-full w-28 mt-3 pl-3'>meeting link</label> */}
                        {/* <label className='p-2 font-semibold mt-1'> Signature image</label>
                <input type="file"
                 className='p-2 h-10 w-full  rounded-md' /> */}
                        {/* <label className='p-2 font-semibold mt-3'> Send from</label>
                <input value={props.senderEmail} onChange={(e) => props.setSenderEmail(e.target.value)} 
                    type="email" placeholder='mail@example.com' className='p-2 h-10 border w-full border-gray-200 rounded-md' /> */}
                        {/* <button className='p-3 w-full bg-blue-600 hover:bg-blue-700 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md mt-5 h-12 text-white transition duration-300 
                        ease-in-out transform hover:-translate-y-1'>Save</button> */}
                    </div>
                </div>}

            {active === "CONTACTS" &&
                <div>
                    <div className='p-5'>
                        <p>Please add the name and email address of all</p>
                        <p>the contacts that should receive the email</p>
                        <p>message.</p>
                    </div>
                    {contactList.length > 0 && <div className=' h-44 overflow-y-scroll'>
                        {contactList.map((item, index) => {
                            return (
                                <div key={index} className='flex flex-col p-2 justify-between ml-5 m-2 border-2 border-gray-300 rounded-md'>
                                    <p className=''><b>name: </b> {item.name}</p>
                                    <p className=''><b>email: </b> {item.email}</p>
                                    {/* <img src={delete_icon} alt="save list" className='w-5 h-5 mt-3 cursor-pointer' /> */}
                                </div>
                            )
                        })}
                    </div>}
                    <div className='flex flex-col p-2 ml-2 mt-2'>
                        <div className='flex flex-row justify-between'>
                            <input type="text" placeholder='Name' value={name} onChange={(e) => setname(e.target.value)}
                                className={`p-2 h-14 border-2 w-full ${err && !name.trim().length > 0 ? 'border-red-400 bg-red-100' : 'border-gray-300'} rounded-md`} />
                            {/* <input type="text" placeholder='Last name' value={lname} onChange={(e) => setLname(e.target.value)} className={`p-2 h-14 border-2 w-40
                            ${err && !lname.length > 0 ?'border-red-400 bg-red-100' : 'border-gray-300'} rounded-md`} /> */}
                        </div>
                        <input type="email" placeholder='Email address' value={email} onChange={(e) => setEmail(e.target.value)} className={`p-2 h-14 border-2 w-full mt-3 
                        ${err && (!email.trim().length > 0 || !validateEmail(email.trim())) ?
                                'border-red-400 bg-red-100' : 'border-gray-300'} rounded-md`} />
                    </div>
                    <div className='flex flex-col p-2 m-2'>
                        <button onClick={addContact} className='p-3 w-full bg-blue-600 hover:bg-blue-700 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md mt-5 h-12 text-white transition duration-300 
                        ease-in-out transform hover:-translate-y-1'>+Add contacts</button>
                    </div>
                </div>}

        </div>
    )
}
