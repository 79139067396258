

import { connect } from 'react-redux';

import Dashboard from "../components/Dashboard";

import { updateWorkspaceAction, setActiveFlowAction, 
        addWorkspaceAction, addFlowAction, getWorkspaceListAction,
        getWorkflowsListAction, updateFlowAction, updateWorkspaceLogo, getSignedUrl } from "../../../redux/actions/WorkspaceAction";
import { setActiveQaListAction, addNewQAListAction, 
    getQAListAction, getQaAction, editQaAction, deleteQaAction } from '../../../redux/actions/QaAction';


const mapStateToProps = state => {
    return {
        activeWorkspace: state.workspaceState.workspace,
        workspaceList: state.workspaceState.workspaceList,
        activeFlow: state.workspaceState.flow,
        workspaceLoader: state.workspaceState.loading,
        workflowPublishing: state.workspaceState.publishing,
        flowList: state.workspaceState.flowList,
        qaList: state.qaState.qaList,
        activeqaList: state.qaState.activeqaList,
        qaLoader: state.qaState.loading,
        qaUpdateLoader: state.qaState.updating,
        qaDeleteLoader: state.qaState.deleting 
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateWorkspaceAction: (data) => dispatch(updateWorkspaceAction(data)),
        setActiveFlowAction: (data) => dispatch(setActiveFlowAction(data)),
        setActiveQaListAction: (data) => dispatch(setActiveQaListAction(data)),
        addNewQAListAction: (title, qaList, cb) => dispatch(addNewQAListAction(title, qaList, cb)),
        addWorkspaceAction: (title, workspaceList) => dispatch(addWorkspaceAction(title, workspaceList)),
        addFlowAction: (title, flowList, activeWorkspace) => dispatch(addFlowAction(title, flowList, activeWorkspace)),
        getWorkspaceListAction: (cb) => dispatch(getWorkspaceListAction(cb)),
        getWorkflowsListAction: (workspace, cb) => dispatch(getWorkflowsListAction(workspace, cb)),
        getQAListAction: (cb) => dispatch(getQAListAction(cb)),
        getQaAction: (id, cb) => dispatch(getQaAction(id, cb)),
        editQaAction: (data, cb) => dispatch(editQaAction(data, cb)),
        deleteQaAction: (data, cb) => dispatch(deleteQaAction(data, cb)),
        updateFlowAction: (data, cb) => dispatch(updateFlowAction(data, cb)),
        getSignedUrl: (data, cb) => dispatch(getSignedUrl(data, cb)),
        updateWorkspaceLogo: (data, cb) => dispatch(updateWorkspaceLogo(data, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)