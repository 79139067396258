import React, { useEffect, useState } from 'react';
import flowLG from "../../../utils/img/FlowLG.png";
import Login_img from "../../../utils/img/Login-Image-Flow.png";

import { NavLink, useHistory } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ResetPassword(props) {

    const [newPass, setNewPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [token, setToken] = useState(null);
    const [askPassword, setAskPassword] = useState(false);
    const history = useHistory();
    const [notify, setNotify] = useState({
        show: false,
        message: '',
        type: ''
    });

    useEffect(() => {
        if (history && history.location
            && history.location.search && history.location.search.includes("?token=")) {
            let resetToken = history.location.search.split("?token=")[1];
            setToken(resetToken);
            props.verifyTokenAction(resetToken, (data) => {
                if (data.success) {
                    setAskPassword(true);
                } else {
                    setNotify({
                        show: true,
                        message: data.message,
                        type: 'error'
                    });
                    setTimeout(() => {
                        removeSnackBar();
                        history.push("/forgot");
                    }, 2000);
                }
            })
        } else {
            setNotify({
                show: true,
                message: "Reset password link invalid",
                type: 'error'
            });
            setTimeout(() => {
                removeSnackBar();
                history.push("/forgot");
            }, 1500);
        }
    }, []);

    const resetPassword = () => {
        let data = {
            password: newPass,
            token: token
        }
        props.resetPasswordAction(data, (res) => {
            if (res.success) {
                setNotify({
                    show: true,
                    message: res.message,
                    type: 'success'
                });
                setTimeout(() => {
                    removeSnackBar();
                    history.push("/");
                }, 2000);
            } else {
                setNotify({
                    show: true,
                    message: res.message,
                    type: 'error'
                });
                setTimeout(() => {
                    removeSnackBar();
                    setNewPass('');
                    setConfirmPass('');
                }, 3000);
            }
        })
    }

    const removeSnackBar = () => {
        setNotify({
            show: false,
            message: '',
            type: ''
        });
    }

    return (
        <div >
            <div class="flex flex-row">
                <div class="h-screen lg:w-7/12 lg:p-10 lg:pl-14 lg:pt-14">
                    <NavLink to="/" >
                        <img src={flowLG} alt="Flow" className='w-28 cursor-pointer p-1 lg:-mt-10 lg:-ml-10' />
                    </NavLink>
                    <div className='lg:h-3/4 lg:ml-10 md:ml-8 ml-6 lg:mt-10 md:mt-8 mt-6 lg:p-16 md:p-6 p-4'>

                        <form >
                            <h2 className='font-bold text-4xl p-2'>Reset your password</h2>
                            <p className='text-md text-gray-500 p-2'>Enter your new password,
                             password should have minumum 8 character long </p>
                            {askPassword ? <div>
                                <div className="mt-5 p-2">
                                    <label htmlFor="new-pass" className="block text-sm font-medium text-gray-800">
                                        New password
                                    </label>
                                    <input
                                        type="password"
                                        name="new-pass"
                                        id="new-pass"
                                        value={newPass}
                                        onChange={(e) => setNewPass(e.target.value)}
                                        placeholder='Enter new password'
                                        className="mt-2 p-3 bg-white block w-full h-12 shadow-sm sm:text-sm border border-gray-300 rounded-md"
                                    />
                                </div>
                                <div className="p-2">
                                    <label htmlFor="new-pass" className="block text-sm font-medium text-gray-800">
                                        Confirm password
                                    </label>
                                    <input
                                        type="password"
                                        name="new-pass"
                                        id="new-pass"
                                        value={confirmPass}
                                        onChange={(e) => setConfirmPass(e.target.value)}
                                        placeholder='Confirm password'
                                        className="mt-2 p-3 bg-white block w-full h-12 shadow-sm sm:text-sm border border-gray-300 rounded-md"
                                    />
                                </div>

                                {props.forgotPasswordLoader ? <div className="h-16 mt-5 w-full bg-gray-400 rounded-lg flex flex-row justify-center">
                                    <span className='w-8 h-8 mt-5 animate-spin bg-none border-b-2 border-white rounded-full'></span>
                                    <p className='p-5 animate-pulse text-white' >updating new password</p>
                                </div>
                                    : <div className='p-3 text-white'>
                                        {newPass.length > 7 && newPass === confirmPass ?
                                            <button type="button" onClick={resetPassword} className='w-full h-16 bg-blue-600 rounded-lg p-3 text-white font-medium'>Reset password</button>
                                            : 
                                            <Tooltip title="Enter valid password" placement='top'>
                                            <button type="button" disabled className='w-full h-16 bg-gray-400 rounded-lg p-3 text-white font-medium'>Reset password</button>
                                            </Tooltip> }
                                    </div>}

                            </div> :
                                <div className="h-16 mt-5 w-ful rounded-lg flex flex-row justify-center">
                                    <span className='w-16 h-16 mt-10 animate-spin bg-none border-b-2 border-blue-400 rounded-full'></span>
                                </div>}

                        </form>

                    </div>
                </div>


                <div className='lg:block hidden bg-banner h-screen w-5/12 p-10'>
                    <div class="login-img">
                        <img src={Login_img} alt="Flow - workflows" className='p-2' />
                    </div>
                    <div className='text-center text-white mt-10' >
                        <h2 className='font-bold text-4xl' >Create your own <br />
                            Voice Workflow AI</h2>
                        <p className=' font-medium text-lg p-5' >Flow helps teams to design, prototype and <br />
                            launch voice workflow assistants.</p>
                    </div>
                </div>

            </div>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={notify.show} autoHideDuration={3000}>
                <Alert severity={notify.type} sx={{ width: '100%' }}>
                    {notify.message}
                </Alert>
            </Snackbar>
        </div>
    )
}
