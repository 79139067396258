

import React, { useState, useEffect } from 'react';
import Auth from './auth';
import Dashboard from './dashboard';
import Loader from './auth/components/Loader';
import moment from "moment";

const withAuthentication = (Layout) => ({ ...props }) => {

    const [authenticated, setAuthenticated] = useState(false);
    const [showLoader, setShowLoader] = useState(false);


    useEffect(() => {
        setShowLoader(true);
        setAuth();
        return () => {
            setShowLoader(false);
        }
    }, []);

    const setAuth = () => {
        let user = localStorage.getItem("user");
        let token = localStorage.getItem("token");
        let tempUser = sessionStorage.getItem("user");
        let tempToken = sessionStorage.getItem("token");
        if(user && token) {
            setAuthenticated(true);
            setShowLoader(false);
            sessionStorage.setItem("user", user);
            sessionStorage.setItem("token", token);
        } else if (tempUser && tempToken) {
            setAuthenticated(true);
            setShowLoader(false);
        } else {
            setAuthenticated(false);
            setShowLoader(false);
        }
    }

    if (showLoader) {
        return <Loader />
    } else if (authenticated) {
        return <Dashboard />
    } else {
        return <Auth />
    }
}



export default withAuthentication(Dashboard);