import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import flowLG from "../../../utils/img/FlowLG.png";
import Login_img from "../../../utils/img/Login-Image-Flow.png";
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



export default function Login(props) {

    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const [notify, setNotify] = useState({
        show: false,
        message: '',
        type: ''
    });

    let routeHistory = useHistory();

    const getToolTipText = () => {
        if (!user.length > 0 && !password.length > 0) {
            return "Enter valid username and password"
        } else if (user.length > 0) {
            return "Enter valid password"
        } else {
            return "Enter valid username"
        }
    }

    const login = () => {
        props.loginAction({
            username: user,
            password: password,
            remember: remember
        }, (data) => {
            if (data.success) {
                setNotify({
                    show: true,
                    message: 'User login success!',
                    type: 'success'
                });
                window.location.reload();
            } else {
                setNotify({
                    show: true,
                    message: data.message,
                    type: 'error'
                });
                setTimeout(() => {
                    removeSnackBar();
                }, 3000);
            }
        });
    }


    const removeSnackBar = () => {
        setNotify({
            show: false,
            message: '',
            type: ''
        });
    }

    return (
        <div >
            <div class="flex flex-row">
                <div class="h-screen lg:w-7/12 lg:p-10 lg:pl-14 lg:pt-14">
                    <img src={flowLG} alt="Flow" className='w-28 p-1 lg:-mt-10 lg:-ml-10' />
                    <div className='lg:h-3/4 lg:ml-10 md:ml-8 ml-6 lg:mt-10 md:mt-8 mt-6 lg:p-16 md:p-6 p-4'>

                        <h2 className='font-bold text-4xl p-2'>Login</h2>
                        <p className='text-md text-gray-500 p-2'>Welcome back, please use your login details to enter the platform. </p>
                        <div className="mt-5 p-2">
                            <label htmlFor="email-address" className="block text-sm font-medium text-gray-800">
                                Username
                            </label>
                            <input
                                type="text"
                                name="email-address"
                                value={user}
                                required
                                onChange={(e) => setUser(e.target.value)}
                                id="email-address"
                                placeholder='Enter your username'
                                className="mt-1 p-2 bg-white block w-full h-12 shadow-sm sm:text-s border border-gray-300 rounded-md"
                            />
                        </div>
                        <div className="p-2">
                            <label htmlFor="password" className="block text-sm font-medium text-gray-800">
                                Password
                            </label>
                            <input
                                type="password"
                                name="password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                id="password"
                                placeholder='Password'
                                className="mt-1 p-2 bg-white block w-full h-12 shadow-sm sm:text-s border border-gray-300 rounded-md"
                            />
                        </div>

                        <div className='flex flex-row justify-between p-2 w-full' >
                            <div className='flex flex-row'>
                                <input onChange={(e) => setRemember(e.target.checked)} type="checkbox" class="h-5 w-5 p-2 mt-2" />
                                <label className='font-bold text-md text-gray-900 p-1 ml-1'> Remember me
                                </label></div>
                            <NavLink to="/forgot" > <p className='font-bold lg:text-md p-1 hover:text-blue-500'>
                                Forgot Password?</p></NavLink>
                        </div>

                        {!props.loading ? <div className='p-3 text-white'>
                            {user.length > 0 && password.length > 0 ?
                                <button onClick={login} type="submit" className='w-full h-16 bg-blue-600 rounded-lg p-3
                                 text-white font-medium'>Login</button> :
                                <Tooltip title={getToolTipText()} placement="top" >
                                    <button type="button" className='w-full h-16 bg-gray-400 rounded-lg p-3
                                 text-white font-medium cursor-not-allowed'>Login</button>
                                </Tooltip>}
                        </div> : <div className="p-3 w-full h-16 rounded-lg
                                 text-white font-medium cursor-wait">
                            <div className="h-16 w-full bg-gray-500 rounded-lg flex flex-row justify-center">
                                <span className='w-8 h-8 mt-5 animate-spin bg-none border-b-2 border-white rounded-full'></span>
                                <p className='p-5 animate-pulse' >please wait, logging in ...</p>
                            </div>
                        </div>}

                    </div>
                </div>


                <div className='lg:block hidden bg-banner h-screen w-5/12 p-10'>
                    <div class="login-img">
                        <img src={Login_img} alt="Flow - workflows" className='p-2' />
                    </div>
                    <div className='text-center text-white mt-10' >
                        <h2 className='font-bold text-4xl' >Create your own <br />
                            Voice Workflow AI</h2>
                        <p className=' font-medium text-lg p-5' >Flow helps teams to design, prototype and <br />
                            launch voice workflow assistants.</p>
                    </div>
                </div>

                <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={notify.show} autoHideDuration={3000}>
                    <Alert severity={notify.type} sx={{ width: '100%' }}>
                        {notify.message}
                    </Alert>
                </Snackbar>

            </div>
        </div>
    )
}
