import React from 'react';
import qa_icon_active from "../../../utils/img/qa_icon_active.png";
import flow_light from "../../../utils/img/flow-light.png";
import save from "../../../utils/img/save.png";
import upload from "../../../utils/img/upload.png";
import delete_icon from "../../../utils/img/delete.png";
import remove_icon from "../../../utils/img/remove.png";
import arrow from "../../../utils/img/arrow-left.png";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';


export default function TableRows(props) {
    const { row, removeFromList, editQna } = props;
    const [open, setOpen] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [question, setQuestion] = React.useState(row.q ? row.q : "");
    const [answer, setAnswer] = React.useState(row.a ? row.a : "");
    const [alternativeQuestion, setAlternativeQuestion] = React.useState("");
    const [alternatives, setAlternatives] = React.useState(
        row.aq ? row.aq : []);

    const addNewAlternativeQuestion = () => {
        let list = [...alternatives, alternativeQuestion];
        setAlternatives(list);
        setAlternativeQuestion("");
    }

    const removeAlternativeQuestion = (ind) => {
        let newList = alternatives.filter((item, index) => index != ind);
        setAlternatives(newList);
    }

    const updateQnA = () => {
        let newRow = {
            id: row.id,
            q: question,
            a: answer,
            aq: alternatives
        }
        editQna(newRow);
        setEdit(false);
        setOpen(false);
    }

    return (
        <React.Fragment >
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    {alternatives.length > 0 && <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton> }
                </TableCell>
                <TableCell component="th" scope="row">
                    <input defaultValue={question} disabled={!edit}
                        onChange={(e) => setQuestion(e.target.value)}
                        className={`bg-white p-1 w-full ${edit && 'border-2'} rounded-md`} />
                </TableCell>
                <TableCell >
                    <input defaultValue={answer} disabled={!edit}
                        onChange={(e) => setAnswer(e.target.value)}
                        className={`bg-white p-1 w-full ${edit && 'border-2'} rounded-md`} />
                </TableCell>
                <TableCell >
                    <div className='flex flex-row w-auto'>
                        {!edit ? <div className={`rounded-lg mr-2 hover:bg-gray-300`}>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setEdit(true);
                                    setOpen(true);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </div> :
                            <div className={`rounded-lg mr-2 hover:bg-green-300`}>
                                <IconButton
                                    size="small"
                                    color="success"
                                    onClick={() => updateQnA()}
                                >
                                    <DoneAllIcon />
                                </IconButton>
                            </div>}
                        <div className={`rounded-lg ml-2 hover:bg-red-300`}>
                            <IconButton
                                size="small"
                                color='error'
                                onClick={() => removeFromList(row.id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto">
                        {(edit || alternatives.length > 0) && <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Alternative questions:
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    {alternatives.map((historyRow, index) => (
                                        <TableRow key={historyRow}>
                                            <TableCell component="th" scope="row">
                                                <div className='flex flex-row justify-start' >
                                                    {edit && <img src={remove_icon} alt="save list"
                                                        onClick={() => removeAlternativeQuestion(index)}
                                                        className='w-5 h-5 cursor-pointer mr-2' /> }
                                                        {/* <img src={arrow} alt="save list" style={{ transform: "rotateY(180deg)" }}
                                                            className='w-5 h-5 p-1 cursor-pointer mr-2 rotate-180' />} */}
                                                    <input defaultValue={historyRow} disabled
                                                        className={`bg-white w-full rounded-md`} />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {edit && <div className='flex flex-row justify-start w-full mt-1'>
                                <input type="text" placeholder='Add alternative question'
                                    value={alternativeQuestion} onChange={(e) => setAlternativeQuestion(e.target.value)}
                                    className={`p-2 border-2 border-gray-200 h-10 overflow-hidden w-2/3 rounded-md`} ></input>
                                {alternativeQuestion.length > 0 ?
                                    < button className='p-2 ml-1 border-2 rounded-md border-blue-300 bg-blue-200
                     transition duration-300 hover:bg-blue-500 text-sm font-semibold hover:border-blue-500'
                                        onClick={addNewAlternativeQuestion} >ADD</button>
                                    : <button className='p-2 ml-1 border-2 rounded-md border-gray-300 bg-gray-200
                     transition duration-300 text-sm font-semibold ease-in-out transform' disabled >ADD</button>}
                            </div>}
                        </Box>}
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}
