

import { 
    UPDATE_USER_DETAILS,
    SET_LOGIN_LOADER,
    SET_FORGOT_LOADER
} from "../types";


const initialState = {
    user: sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : null,
    token: sessionStorage.getItem("token") ? sessionStorage.getItem("token") : null,
    loading: false,
    forgotLoader: false
}


export default (state = initialState, { type, data }) => {
    switch (type) {    
        case UPDATE_USER_DETAILS:
            return { ...state, user: data.user, token: data.token, loading: false } 
        case SET_LOGIN_LOADER:
            return { ...state, loading: data }  
        case SET_FORGOT_LOADER:
            return { ...state, forgotLoader: data }           
        default:
            return state
    }
}