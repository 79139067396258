


import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import Login from "./Login";
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';




export default function Routes (props) {
    return (
      <Router>
        <Switch>
        <Route exact path="/">
            <Login {...props} />
          </Route>
          <Route path="/forgot">
            <ForgotPassword {...props} />
          </Route>
          <Route path="/reset">
            <ResetPassword {...props} />
          </Route>
          <Route path="*" >
            <Login {...props} />
          </Route>
        </Switch>
    </Router>
    );
  }
  