import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import workspaceStore from "./reducers/WorkspaceReducer";
import qaListStore from "./reducers/QaReducer";
import userReducer from './reducers/userReducer';

const rootReducer = combineReducers({
    workspaceState: workspaceStore,
    qaState: qaListStore,
    userState: userReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;