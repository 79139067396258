

import React from 'react';

import help_res from "../../../utils/img/help_res.png";

export default function NoAnswer(props) {
    return (
        <div className='flex flex-col fixed'>
            <div className='flex flex-row mt-10'>
                <img src={help_res} alt="No answer response" className='w-16 h-16 p-3 bg-blue-400 border-4 border-white rounded-tr-sm 
                    rounded-bl-sm rounded-tl-2xl rounded-br-2xl shadow-lg ml-4 ' />
                <h4 className='font-semibold text-xl p-5'>No answer response</h4>
            </div>
            <div className='p-5'>
                <p>Write out the voice response below when the AI can't</p>
                <p>find an answer to the question asked. Always include</p>
                <p>a <b>Yes/No</b>  option to connect for human support.</p>
            </div>
            <div className='flex flex-col p-2 m-2'>
                <label className=' p-2'>Message</label>
                <textarea defaultValue={props.noAnsRes} onChange={(e) => props.setNoAnsRes(e.target.value)} name="message" id="" rows="6" className='p-3 border-2 border-gray-200 rounded-lg' 
                        placeholder="Example: sorry, I don't have an answer to that. Should I connect you my human colleague?">
                        </textarea>

                {/* <button className='p-3 w-full bg-blue-600 hover:bg-blue-700 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md mt-5 h-12 text-white transition duration-300 
                        ease-in-out transform hover:-translate-y-1'>Save</button> */}
            </div>
        </div>
    )
}
