

import React, { useState, useEffect } from 'react';

import send_sms from "../../../utils/img/send_sms.png";
import delete_icon from "../../../utils/img/delete.png";

export default function SendSms(props) {
    const [active, setActive] = useState("MESSAGE");
    const [contactList, setContactList] = useState([]);
    const [name, setname] = useState('');
    // const [lname, setLname] = useState('');
    const [phone, setPhone] = useState('');
    const [err, setErr] = useState(false);

    useEffect(() => {
        parseContacts();
    }, []);

    const parseContacts = () => {
        let list = props.receiverPhones.split(",");
        let newList = [];
        for (let i = 0; i < list.length; i++) {
            if (list[i].length > 0) {
                let tempList = list[i].split(";");
                newList = [...newList, { name: tempList[0], phone: tempList[1] }]
            }
        }
        setContactList(newList);
    }

    const addContact = () => {
        if (name.trim().length > 0 && (phone.trim().length > 0 && validatePhone(phone.trim()))) {
            let newList = [...contactList, { name: name, phone: phone }];
            let newString = props.receiverPhones + `,${name};${phone}`;
            setContactList(newList);
            props.setReceiverPhones(newString);
            setname('');
            setPhone('');
        } else {
            setErr(true);
            setTimeout(() => {
                setErr(false);
            }, 1500);
        }
    }

    const validatePhone = (phone) => {
        return phone.trim().length > 2 ? /^\+?\d*$/.test(phone.trim()) : false
    };

    return (
        <div className='flex flex-col fixed'>
            <div className='flex flex-row mt-10'>
                <img src={send_sms} alt="Listen for question" className='w-16 h-16 p-3 bg-yellow-400 border-4 border-white rounded-tr-sm 
                    rounded-bl-sm rounded-tl-2xl rounded-br-2xl shadow-lg ml-4 ' />
                <h4 className='font-semibold text-xl p-5'>Send SMS</h4>
            </div>

            <div className='p-5 flex flex-row'>
                {active === "MESSAGE" ? <p className='p-5 font-semibold text-medium cursor-pointer text-blue-500 border-b-2 border-blue-500'>Message</p>
                    : <p onClick={() => setActive("MESSAGE")} className='p-5 font-semibold text-medium cursor-pointer'>Message</p>}
                {active === "CONTACTS" ? <p className='p-5 font-semibold text-medium cursor-pointer text-blue-500 border-b-2 border-blue-500'>Contacts</p>
                    : <p onClick={() => setActive("CONTACTS")} className='p-5 font-semibold text-medium cursor-pointer'>Contacts</p>}
            </div>

            {active === "MESSAGE" &&
                <div>
                    <div className='p-5'>
                        <p>Please write out the text message to send out</p>
                        <p>when human assistance is requested by the</p>
                        <p>user.</p>
                    </div>
                    <div className='flex flex-col p-2 m-2'>
                        <label className=' p-2'>Message</label>
                        <textarea defaultValue={props.smsMessage} onChange={(e) => props.setSmsMessage(e.target.value)}
                            name="message" id="" rows="6" className='p-3 border-2 border-gray-200 rounded-lg'
                            placeholder="Type in your text message here and use the meeting link button below to add the link to the meeting in your message."></textarea>
                        {/* <label className='p-2 bg-gray-300 rounded-full w-28 mt-5 pl-3'>meeting link</label> */}
                        {/* <button className='p-3 w-full bg-blue-600 hover:bg-blue-700 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md mt-5 h-12 text-white transition duration-300 
                        ease-in-out transform hover:-translate-y-1'>Save</button> */}
                    </div>
                </div>}

            {active === "CONTACTS" &&
                <div>
                    <div className='p-5'>
                        <p>Please add the name and phone numbers of</p>
                        <p>all the contacts that should receive the text</p>
                        <p>message.</p>
                    </div>
                    {(contactList.length > 0) && <div className=' h-44 overflow-y-scroll'>
                        {contactList.map((item, index) => {
                            return (
                                <div key={index} className='flex flex-col p-4 justify-between ml-5 m-2 border-2 border-gray-300 rounded-md'>
                                    <p className=''><b>name:</b> {item.name}</p>
                                    <p className=''><b>phone:</b> {item.phone}</p>
                                    {/* <img src={delete_icon} alt="save list" className='w-5 h-5 mt-3 cursor-pointer' /> */}
                                </div>
                            )
                        })}
                    </div>}
                    <div className='flex flex-col p-2 ml-2 mt-2'>
                        <div className='flex flex-row justify-between'>
                            <input type="text" placeholder='Name' value={name} onChange={(e) => setname(e.target.value)}
                                className={`p-2 h-14 border-2 w-full ${err && !name.trim().length > 0 ? 'border-red-400 bg-red-100' : 'border-gray-300'} rounded-md`} />
                            {/* <input type="text" placeholder='Last name' value={lname} onChange={(e) => setLname(e.target.value)} className={`p-2 h-14 border-2 w-40
                            ${err && !lname.length > 0 ? 'border-red-400 bg-red-100' : 'border-gray-300'} rounded-md`} /> */}
                        </div>
                        <input type="text" placeholder='Phone number' value={phone} onChange={(e) => setPhone(e.target.value)} className={`p-2 h-14 border-2 w-full mt-3 
                        ${err && (!phone.trim().length > 0 || !validatePhone(phone.trim())) ? 'border-red-400 bg-red-100' : 'border-gray-300'} rounded-md`} />
                    </div>
                    <div className='flex flex-col p-2 m-2'>
                        <button onClick={addContact} className='p-3 w-full bg-blue-600 hover:bg-blue-700 hover:shadow-lg hover:border 
                        hover:border-gray-100 rounded-md mt-5 h-12 text-white transition duration-300 
                        ease-in-out transform hover:-translate-y-1'>+Add contacts</button>
                    </div>
                </div>}

        </div>
    )
}
