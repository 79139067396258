

import {
    UPDATE_QA_LIST,
    SET_ACTIVE_QA_LIST,
    ADD_NEW_QA_LIST,
    QA_LIST,
    QA_LIST_LOADER,
    QA_LIST_UPDATE,
    QA_LIST_DELETE
} from "../types";

// import qaList from "../../mockData/qalist.json";


const initialState = {
    qaList: [],
    activeqaList: null,
    qa: [],
    loading: false,
    updating: false,
    deleting: false
}


export default (state = initialState, { type, data }) => {
    switch (type) {    
        case UPDATE_QA_LIST:
            return { ...state, qaList: data }        
        case SET_ACTIVE_QA_LIST:
            return { ...state, activeqaList: data } 
        case ADD_NEW_QA_LIST:
            return { ...state, qaList: [...state.qaList, data] } 
        case QA_LIST:
            return { ...state, qa: data } 
        case QA_LIST_LOADER:
            return { ...state, loading: data } 
        case QA_LIST_UPDATE:
            return { ...state, updating: data } 
        case QA_LIST_DELETE:
            return { ...state, deleting: data } 
        default:
            return state
    }
}